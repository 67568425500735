<template>
  <v-flex class="px-3" xs12>
    <div class="expande-horizontal centraliza fonte column">
      <v-flex xs12 md3>
        <div
          class="expande-horizontal centraliza"
          style="justify-content: center; !important"
        >
          <LottieAnimation
            ref="anim"
            :loop="true"
            :animationData="require('@/apps/shared/assets/new_insume.json')"
          />
        </div>
      </v-flex>
      <v-flex class="pt-6" xs12>
        <v-list style="max-height: 230px; overflow: auto;" dark class="fonte" color="transparent" dense>
          <v-list-item
            @click="$emit('go-to-form-group')"
            class="item-list-aditional"
            v-for="(item, index) in group.itens"
            :key="`item-group-adicional-${index}`"
          >
            <v-avatar>
              <v-icon color="white">mdi-basket</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.descricao_personalizada }}
              </v-list-item-title>
              <v-list-item-subtitle>
                preço {{ $helper.formataSaldo(item.preco) }}
              </v-list-item-subtitle>
              <!-- <span class="white--text fonteMini text-lowercase">
                O impacto no estoque por venda finalizada será de {{ item.quantidade }} {{ item.unidade_estoque_minimo.nome }} de {{ item.nome }}. 
              </span> -->
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="removerAdicionalDoGrupo(false, item, index)" icon>
                <v-icon>mdi-delete-circle-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list dark class="fonte" color="transparent" dense>
          <v-list-item
            @click="$emit('search-items')"
            class="item-list-aditional mt-6"
            :style="`background: ${$theme.primary}`"
          >
            <v-avatar>
              <v-icon color="white">mdi-vector-square-plus</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Adicionar nova opção
              </v-list-item-title>
              <span class="white--text fonteMini">
                Clique aqui para adicionar novos itens
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="$emit('back-step-one')"
            class="item-list-aditional"
            :style="`background: ${$theme.primary}`"
          >
            <v-avatar>
              <v-icon color="white">mdi-chevron-left</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Voltar
              </v-list-item-title>
              <span class="white--text fonteMini">
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: { LottieAnimation },
  props: ["group", "index_group", "removerAdicionalDoGrupo"]
};
</script>

<style>
.item-list-aditional {
  margin-bottom: 6px;
  background: #673AB7;
  border-radius: 6px;
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
}
</style>
