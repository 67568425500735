<template>
  <v-dialog
    persistent
    transition="slide-x-transition"
    fullscreen
    v-model="opened"
  >
    <div class="aditional-container centraliza">
      <v-flex
        :class="{
          'px-3': $vuetify.breakpoint.lgAndUp
        }"
        xs12
        md6
        class="fonte"
      >
        <div
          :class="{
            'group-container': $vuetify.breakpoint.lgAndUp,
            'group-container-mobile': $vuetify.breakpoint.smAndDown
          }"
        >
          <v-flex xs12>
            <Header @close="close" />
          </v-flex>
          <v-flex xs12>
            <v-window touchless v-model="step">
              <v-window-item :value="1">
                <informations
                  @go-to-form-group="step = 2"
                  @search-items="step = 4"
                  @view-items="step = 3"
                  :group="group"
                  :index_group="index_group"
                  @save="save"
                  @close="close"
                />
              </v-window-item>

              <v-window-item :value="2">
                <form-group
                  :index_group="index_group"
                  @back-step-one="step = 1"
                  @view-items="step = 3"
                  :group="group"
                  @save="saveGroup"
                  @close="close"
                />
              </v-window-item>

              <v-window-item :value="3">
                <view-items
                  :group="group"
                  :index_group="index_group"
                  @back-step-one="step = 1"
                  @search-items="step = 4"
                  @save="saveGroup"
                  @close="close"
                  :removerAdicionalDoGrupo="removerAdicionalDoGrupo"
                />
              </v-window-item>

              <v-window-item :value="4">
                <search-items
                  :group="group"
                  :index_group="index_group"
                  @back-step-one="step = 1"
                  @go-to-items="step = 3"
                  :importItems="importItems"
                  :saveGroup="saveGroup"
                  @close="close"
                />
              </v-window-item>
            </v-window>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import Header from "./header.vue";
import Informations from "./informations.vue";
import FormGroup from "./form-group.vue";
import ViewItems from "./view-items.vue";
import SearchItems from "./search-items.vue";
import { mapActions } from "vuex";

export default {
  props: ["save"],
  components: { Header, Informations, FormGroup, ViewItems, SearchItems },
  data() {
    return {
      group: {
        nome_grupo: "",
        itens: []
      },
      index_group: false,
      opened: false,
      step: 1
    };
  },
  methods: {
    setIndexGroup(index) {
      this.index_group = index;
      this.$forceUpdate();
    },
    importItems(payload_import) {
      if (Array.isArray(this.group.itens)) {
        payload_import.map(item_import => {
          let import_treated = item_import;
          delete import_treated.market_to_import;
          this.group.itens.push(import_treated);
        });
      } else {
        let imports_treateds = [];
        payload_import.map(i => {
          let import_treated = i;
          delete import_treated.market_to_import;
          imports_treateds.push(import_treated);
        });
        this.group.itens = imports_treateds;
      }
      this.saveGroup();
    },
    saveGroup() {
      this.save(this.group, this.index_group);
      // this.createGlobalMessage({
      //   type: "success",
      //   message: "Grupo atualizado",
      //   timeout: 1500
      // });
      this.step = 1;
    },
    ...mapActions(["createGlobalMessage", "createConfirmAction"]),
    open(index_group, group) {
      if ((index_group || index_group === 0) && group) {
        let items_fixeds = group.itens.filter(g => g);
        this.index_group = index_group;
        this.group = {
          ...group,
          itens: items_fixeds
        };
        this.step = 1;
      } else {
        this.group = {
          itens: []
        };
        this.step = 2;
        this.index_group = false;
      }
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    calcUnidadeDeMedida(value) {
      if (
        value.unidade_estoque_minimo.valor == "und" ||
        value.unidade_estoque_minimo.valor == "UND"
      ) {
        return "unidade(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "kg" ||
        value.unidade_estoque_minimo.valor == "KG"
      ) {
        return "quilo(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "g" ||
        value.unidade_estoque_minimo.valor == "GRAMA"
      ) {
        return "grama(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "l" ||
        value.unidade_estoque_minimo.valor == "L"
      ) {
        return "litro(s)";
      } else if (value.unidade_estoque_minimo.valor == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_estoque_minimo.valor;
      }
    },
    removerAdicionalDoGrupo(confirmation, item, index) {
      if (confirmation) {
        this.group.itens.splice(index, 1);
        this.createGlobalMessage({
          timeout: 1000,
          icon: "mdi-success",
          message: `Removemos ${item.quantidade} ${this.calcUnidadeDeMedida(
            item
          )} de ${item.nome} do grupo ${this.group.nome_grupo} com sucesso!`,
          type: "success"
        });
      } else {
        this.createConfirmAction({
          message: `Deseja remover ${
            item.quantidade
          } ${this.calcUnidadeDeMedida(item)}?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerAdicionalDoGrupo(true, item, index),
          action_value: "ok"
        });
      }
      this.save(this.group);
    },
    importItems() {
      this.$emit("import_adicionals", this.selected_items);
    }
  }
};
</script>

<style>
.expand-vertical {
  min-height: 100vh;
}
.group-container {
  background: #f2f2f2;
  min-height: 600px;
  overflow: auto;
  width: 100%;
  display: flex;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  flex-wrap: wrap;
}
.group-container-mobile {
  background: #f2f2f2;
  height: 100vh;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
}
.group-header {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  width: 100%;
  flex-wrap: no-wrap;
}
.aditional-container {
  display: flex;
  height: 100vh;
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}
</style>
