<template>
  <v-form ref="formGrupoAdicional" class="px-6">
    <v-flex class="pt-3" xs12>
      <div class="expande-horizontal mb-6 centraliza column">
        <v-icon :color="$theme.secondary" size="60"
          >mdi-vector-square-edit</v-icon
        >
        <span class="fonte text-center mt-4 mb-3 grey--text "
          >Informe o nome do grupo, quantos itens podem ser escolhidos e se ele
          é obrigatório.</span
        >
      </div>
    </v-flex>
    <v-flex xs12>
      <div class="expande-horizontal wrap">
        <v-flex class="pr-3" xs12>
          <span class="fonte fonteMini font-weight-bold blue--text"
            >Nome do grupo</span
          >
          <v-text-field
            solo
            color="#fff"
            dense
            flat
            clearable
            placeholder="Ex: Adicional de Molho"
            :rules="[v => !!v || 'Campo obrigatório']"
            ref="nomeAdicionalField"
            v-model="group.nome_grupo"
          ></v-text-field>
        </v-flex>
      </div>
    </v-flex>
    <v-flex xs12>
      <div class="expande-horizontal wrap">
        <v-flex class="pr-3" xs12>
          <span class="fonte fonteMini font-weight-bold blue--text"
            >Máximo de itens que podem ser adicionados</span
          >
          <v-text-field
            solo
            color="#fff"
            dense
            flat
            clearable
            v-mask="['#', '##']"
            placeholder="Ex: 2"
            :rules="[v => !!v || 'Campo obrigatório']"
            ref="maximoAdicionalField"
            v-model="group.maximo"
          ></v-text-field>
        </v-flex>
      </div>
    </v-flex>
    <v-flex class="mb-6" xs12>
      <v-switch
        :color="$theme.secondary"
        v-model="group.obrigatorio"
        class="fonte fonteMini"
        label="Obrigatório"
      ></v-switch>
    </v-flex>
    <v-flex class="pb-1" xs12>
      <div
        class="expande-horizontal centraliza fonte font-weight-bold centraliza wrap"
      >
        <v-btn
          :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
          :block="$vuetify.breakpoint.smAndDown"
          dark
          class="mx-2 font-weight-bold fonte"
          :color="$theme.secondary"
          @click="$emit('back-step-one')"
          v-if="has_index_group"
        >
          <v-icon>mdi-chevron-left</v-icon> Voltar</v-btn
        >
        <v-btn
          :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
          :block="$vuetify.breakpoint.smAndDown"
          dark
          class="mx-2 font-weight-bold fonte"
          :color="$theme.secondary"
          @click="$emit('save')"
          v-if="has_index_group"
          >Salvar <v-icon>mdi-content-save-outline</v-icon></v-btn
        >
        <v-btn
          :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
          :block="$vuetify.breakpoint.smAndDown"
          dark
          @click="$emit('view-items')"
          class="mx-2 font-weight-bold fonte"
          :color="$theme.secondary"
          >Adicionar itens <v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </div>
    </v-flex>
  </v-form>
</template>

<script>
export default {
  props: ["group", "index_group"],
  computed: {
    has_index_group() {
      if (this.index_group === 0 || this.index_group) {
        return true;
      }
      return false;
    }
  }
};
</script>
