<template>
  <v-dialog
    persistent
    transition="slide-x-transition"
    fullscreen
    v-model="opened"
  >
    <div class="aditional-container centraliza">
      <v-flex xs12 md6 class="px-3 fonte">
        <div class="group-container">
          <v-flex xs12>
            <Header @close="close" />
          </v-flex>
          <v-flex xs12>
            <search-items :importItems="importItems" />
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import Header from "./header.vue";
import SearchItems from "./search-items.vue";
import { mapActions } from "vuex";

export default {
  props: ["saveImportAditionals"],
  components: { Header, SearchItems },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    importItems(payload_import) {
      let import_treateds = [];
      payload_import.map(item_import => {
        if (item_import) {
          let import_treated = item_import;
          delete import_treated.marked_to_import;
          import_treateds.push(import_treated);
        }
      });
      this.saveGroup(import_treateds);
    },
    saveGroup(import_treateds) {
      this.saveImportAditionals(import_treateds);
      this.createGlobalMessage({
        type: "success",
        message: "A importação foi feita com sucesso",
        timeout: 1500
      });
      this.close();
    },
    ...mapActions(["createGlobalMessage", "createConfirmAction"]),
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    }
  }
};
</script>

<style>
.expand-vertical {
  min-height: 100vh;
}
.group-container {
  background: #f2f2f2;
  min-height: 600px;
  overflow: auto;
  width: 100%;
  display: flex;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  flex-wrap: wrap;
}
.group-header {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  width: 100%;
  flex-wrap: no-wrap;
}
.aditional-container {
  display: flex;
  height: 100vh;
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}
</style>
