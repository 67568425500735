<template>
  <v-dialog width="700" v-model="opened">
    <div class="expande-horizontal wrap" style="background: #f2f2f2;">
      <v-flex xs12 class="pa-3 pb-0">
        <div class="expande-horizontal centraliza">
          <div class="expande-horizontal column title-item">
            <v-avatar
              @click="close"
              size="36"
              style="cursor: pointer;"
              color="#e5e5e5"
              icon
            >
              <v-icon color="#000" size="26">mdi-close</v-icon>
            </v-avatar>
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center; height: 40px;"
          >
            <v-icon color="#666" size="38" style="padding-bottom: 3px;"
              >mdi-list-box-outline</v-icon
            >
            <span
              style="font-size: 19pt; text-wrap: nowrap;"
              class="fonte-bold"
            >
              Novo Produto
            </span>
          </div>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-window touchless v-model="step">
          <v-window-item value="1">
            <v-flex xs12 class="px-3">
              <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                <v-flex class="px-1" xs12>
                  <span class="fonte-bold" style="font-size: 24pt;">
                    Bem vindo(a), escolha o tipo de produto que deseja criar.
                  </span>
                </v-flex>
                <v-flex xs12>
                  <v-list class="px-0" color="transparent" nav>
                    <v-list-item
                      v-for="product_type in product_types"
                      :key="product_type.type"
                      @click="selectProductType(product_type)"
                      style="background: #f2f2f2; border-radius: 12px; margin-bottom: 6px;"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="black--text fonte-bold">
                          {{ product_type.name }}
                        </v-list-item-title>
                        <span style="font-size: 11pt;" class="grey--text">{{
                          product_type.description
                        }}</span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="green">mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </v-window-item>

          <v-window-item value="2">
            <v-flex xs12 class="px-3">
              <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                <v-flex xs12>
                  <v-list class="px-0" color="transparent" nav>
                    <v-list-item
                      style="background: #f2f2f2; border-radius: 12px; margin-bottom: 6px;"
                    >
                      <v-avatar
                        @click="step = '1'"
                        size="36"
                        style="cursor: pointer;"
                        color="#e5e5e5"
                        class="mr-2"
                      >
                        <v-icon color="#000" size="26">mdi-chevron-left</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="black--text fonte-bold">
                          {{ product_type.name }}
                        </v-list-item-title>
                        <span style="font-size: 11pt;" class="grey--text">{{
                          product_type.description
                        }}</span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="green">mdi-check</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    filled
                    color="black"
                    rounded
                    prepend-inner-icon="mdi-tag-text-outline"
                    large
                    v-model="product.nome"
                    placeholder="Informe o nome do seu novo produto/serviço"
                    autofocus
                    :rules="[!!product.nome || 'Preencha este campo']"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    filled
                    color="black"
                    rounded
                    prepend-inner-icon="mdi-barcode"
                    large
                    v-model="product.code"
                    placeholder="Código do produto"
                    :rules="[!!product.code || 'Preencha este campo']"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  v-if="
                    ['preco_unico', 'ficha_tecnica'].includes(product_type.type)
                  "
                  xs12
                >
                  <v-text-field
                    filled
                    color="black"
                    rounded
                    prepend-inner-icon="mdi-cash"
                    large
                    v-model="product.preco_com_markup"
                    placeholder="Preço"
                    v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']"
                    :rules="[
                      !!product.preco_com_markup || 'Preencha este campo'
                    ]"
                  ></v-text-field>
                </v-flex>
                <!-- <div
                  v-if="product_type.type === 'preco_unico'"
                  class="expande-horizontal wrap"
                >
                  <v-flex xs12>
                    <v-text-field
                      filled
                      color="black"
                      rounded
                      prepend-inner-icon="mdi-cash"
                      large
                      v-model="product.preco_com_markup"
                      placeholder="Preço"
                      :rules="[
                        !!product.preco_com_markup || 'Preencha este campo'
                      ]"
                      autofocus
                    ></v-text-field>
                  </v-flex>
                </div> -->
                <v-flex xs12>
                  <div class="expande-horizontal centraliza">
                    <v-btn
                      @click="step = '1'"
                      :loading="loading"
                      color="#333"
                      dark
                      text
                      >Voltar</v-btn
                    >
                    <v-btn
                      @click="createProduct"
                      :loading="loading"
                      x-large
                      color="#333"
                      dark
                      rounded
                      >Avançar</v-btn
                    >
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-window-item>

          <!-- <v-window-item value="3">
            <v-flex xs12 class="px-3">
              <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                <v-flex class="px-1" xs12>
                  <span class="fonte-bold" style="font-size: 24pt;">
                    Ficha técnica
                  </span>
                </v-flex>
                <v-flex xs12>
                  <v-list class="px-0" color="transparent" nav>
                    <v-list-item
                      style="background: #f2f2f2; border-radius: 12px; margin-bottom: 6px;"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="black--text fonte-bold">
                          {{ product_type.name }}
                        </v-list-item-title>
                        <span style="font-size: 11pt;" class="grey--text">{{
                          product_type.description
                        }}</span>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="green">mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
          </v-window-item> -->
          <v-window-item value="20">
            <v-flex xs12 class="px-3">
              <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                <v-flex class="px-1" xs12>
                  <span
                    class="fonte-bold mb-6 expande-horizontal centraliza"
                    style="font-size: 24pt;"
                  >
                    <v-avatar size="120" color="green">
                      <v-icon color="white" size="100">mdi-check</v-icon>
                    </v-avatar>
                  </span>
                </v-flex>
                <v-flex class="px-1" xs12>
                  <div class="expande-horizontal centraliza column">
                    <span class="fonte-bold" style="font-size: 24pt;">
                      Seu novo produto foi cadastrado
                    </span>
                    <span
                      class="fonte grey--text mb-2"
                      style="font-size: 12pt;"
                    >
                      Escolha o que deseja fazer agora
                    </span>
                  </div>
                </v-flex>
                <v-flex xs12 class="mt-3">
                  <div class="expande-horizontal column centraliza">
                    <v-btn
                      @click="openModalConfigProduct"
                      color="black"
                      dark
                      large
                      rounded
                      class="fonte-bold"
                      >Visualizar produto que acabei de cadastrar</v-btn
                    >
                    <v-btn
                      @click="open"
                      color="black"
                      dark
                      large
                      rounded
                      class="fonte-bold"
                      >Cadastrar novo produto</v-btn
                    >
                    <v-btn
                      @click="close"
                      color="black"
                      dark
                      large
                      rounded
                      class="fonte-bold"
                      >Sair</v-btn
                    >
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-window-item>
          <v-window-item value="40">
            <v-flex xs12 class="px-3" md6>
              <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                <v-flex class="px-1" xs12>
                  <span
                    class="fonte-bold mb-6 expande-horizontal centraliza"
                    style="font-size: 24pt;"
                  >
                    <v-avatar size="120" color="orange">
                      <v-icon color="white" size="60">mdi-alert-outline</v-icon>
                    </v-avatar>
                  </span>
                </v-flex>
                <v-flex class="px-1" xs12>
                  <div class="expande-horizontal centraliza column">
                    <span class="fonte-bold" style="font-size: 24pt;">
                      Opa!
                    </span>
                    <span
                      class="fonte grey--text mb-2"
                      style="font-size: 12pt;"
                    >
                      Verifique a internet e tente novamente!
                    </span>
                  </div>
                </v-flex>
                <v-flex xs12 class="mt-3">
                  <div class="expande-horizontal centraliza">
                    <v-btn
                      @click="step = '2'"
                      color="black"
                      dark
                      large
                      rounded
                      class="fonte-bold"
                      >Tentar novamente</v-btn
                    >
                  </div>
                </v-flex>
                <v-flex xs12 class="mt-3">
                  <div class="expande-horizontal centraliza">
                    <v-btn
                      @click="$router.push('/dashboard')"
                      color="black"
                      dark
                      text
                      class="fonte-bold"
                      >Ir para o início</v-btn
                    >
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-window-item>
        </v-window>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      step: "1",
      value: "",
      loading: false,
      error_message: "",
      opened: false,
      product: {},
      product_types: [
        {
          type: "preco_unico",
          name: "Preço Único",
          description:
            "Produtos que não tem variação de preço e nem opções de adicionais. ex: Biscoito, Vassoura, etc."
        },
        {
          type: "ficha_tecnica",
          name: "Produto com ficha técnica",
          description:
            "Produtos que você pode controlar a receita através da ficha técnica, informando os insumos."
        },
        {
          type: "precificado",
          name: "Precificação inteligente com ficha técnica",
          description:
            "O sistema calcula o valor do produto com base em fatores relevantes como a ficha técnica e as despesas."
        },
        {
          type: "oferta",
          name: "Produtos com preço baseado no tamanho, cor, etc...",
          description:
            "Produtos que possuem preços baseados em tamanhos, cores, etc...."
        }
        // {
        //   type: "assinatura",
        //   name: "Assinatura",
        //   description:
        //     "Serviços de prestação continuada que podem ou não ter parcelas definidas. ex: Cobrança, Assinatura de cursos de qualquer natureza, etc..."
        // },
        // {
        //   type: "agendamento",
        //   name: "Agendamento",
        //   description:
        //     "Serviços com data e hora definidas. ex: Agendamento de consultas, Horário no barbeiro, etc..."
        // }
      ],
      product_type: {}
    };
  },
  methods: {
    selectProductType(product_type) {
      this.product = {
        product_type: product_type.type
      };
      this.product_type = product_type;
      this.step = "2";
    },
    open() {
      this.opened = true;
      this.step = "1";
      this.product = {};
    },
    close() {
      this.product = {};
      this.opened = false;
    },
    createProduct() {
      const product = {
        ...this.product,
        type: this.product_type.type,
        _id: this.generateUUID(),
        created_at: new Date()
      };
      this.$emit("createProduct", product);
    },
    openModalConfigProduct() {
      this.$emit("openModalConfigProduct");
    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
    // getDestUser() {
    //   this.error_message = "";
    //   this.loading = true;
    //   this.show_pinbank_account({
    //     channel_code: this.channel_code,
    //     user_code: this.user_code
    //   })
    //     .then(res => {
    //       this.loading = false;
    //       this.pinTargetAccount = res ? JSON.parse(res).Data : {};
    //     })
    //     .catch(err => {
    //       this.loading = false;
    //       this.error_message = err.response.data.message;
    //     });
    // },
  }
};
</script>
