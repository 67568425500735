<template>
  <v-flex xs12>
    <div class="expande-horizontal centraliza fonte column">
      <v-flex xs12 md3>
        <div
          class="expande-horizontal centraliza"
          style="justify-content: center; !important"
        >
          <LottieAnimation
            ref="anim"
            :loop="true"
            :animationData="require('@/apps/shared/assets/new_insume.json')"
          />
        </div>
      </v-flex>
      <v-flex class="px-3" xs12>
        <v-list dark class="fonte" color="transparent" dense>
          <v-list-item
            @click="$emit('go-to-form-group')"
            class="item-list-aditional"
          >
            <v-avatar>
              <v-icon color="white">mdi-ballot-outline</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ group.nome_grupo }}
              </v-list-item-title>
              <span class="white--text fonteMini">
                Descrição do grupo
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="$emit('go-to-form-group')"
            class="item-list-aditional"
          >
            <v-avatar>
              <v-icon color="white">mdi-plus-circle-outline</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ group.maximo }}
              </v-list-item-title>
              <span class="white--text fonteMini">
                Maximo de itens que podem ser adicionados
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="$emit('go-to-form-group')"
            class="item-list-aditional"
          >
            <v-avatar>
              <v-icon color="white">mdi-asterisk</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ group.obrigatorio ? "É obrigatório" : "Não é obrigatório" }}
              </v-list-item-title>
              <span class="white--text fonteMini">
                Define se este grupo é ou não obrigatório
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click="$emit('view-items')" class="item-list-aditional">
            <v-avatar>
              <v-icon color="white">mdi-sitemap-outline</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ group.itens.length }}
              </v-list-item-title>
              <span class="white--text fonteMini">
                Quantidade de itens no grupo
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="$emit('search-items')"
            class="item-list-aditional"
          >
            <v-avatar>
              <v-icon color="white">mdi-vector-square-plus</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Adicionar novos itens
              </v-list-item-title>
              <span class="white--text fonteMini">
                Clique aqui para adicionar novos itens
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="$emit('close')"
            class="item-list-aditional"
            :style="`background: ${$theme.primary}`"
          >
            <v-avatar>
              <v-icon color="white">mdi-chevron-left</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                Sair
              </v-list-item-title>
              <span class="white--text fonteMini">
                Clique para voltar ao produto
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: { LottieAnimation },
  props: ["group"]
};
</script>

<style>
.item-list-aditional {
  margin-bottom: 6px;
  background: #673AB7;
  border-radius: 6px;
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
}
</style>
