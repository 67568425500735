<template>
  <layout
    style="background: #f2f2f2;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div class="expande-horizontal wrap">
      <v-flex xs12 v-if="$vuetify.breakpoint.smAndDown && filter.search === ''">
        <div class="expande-horizontal centraliza">
          <v-flex xs9>
            <LottieAnimation
              class="pa-6 pt-0 pb-0"
              ref="anim"
              :loop="true"
              :animationData="require('@/apps/shared/assets/category.json')"
            />
          </v-flex>
        </div>
      </v-flex>
      <v-flex xs12 md3 style="min-height: 90vh;">
        <Filtros :filter="filter" />
        <v-flex
          class="pa-3"
          v-if="getPersonalizedLoading === 'listando-produtos'"
          xs12
        >
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex class="pa-3 pt-0" v-else xs12>
          <v-list
            dense
            :style="
              `max-height: ${
                $vuetify.breakpoint.smAndDown ? '54vh' : '87vh'
              }; overflow: auto;`
            "
            color="transparent"
            class="pa-0 pr-1 ma-0 list-categories"
          >
            <v-list-item
              class="item-new"
              @click="iniciarCadastro"
              style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
            >
              <v-icon class="mr-3" dark>mdi-plus</v-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold fonte-subtitulo">
                  Nova categoria
                </v-list-item-title>
                <span class="white--text fonteMini">
                  clique aqui para adicionar uma nova categoria
                </span>
              </v-list-item-content>
            </v-list-item>
            <span
              :style="`color: #333;`"
              class="pl-1 fonteMini fonte font-weight-bold"
              >Categorias</span
            >
            <template v-for="(produto, index) in categoryFiltereds">
              <v-list-item
                class="item-selected"
                @click="abrirProduto(produto, index)"
                style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                :key="produto._id"
              >
                <v-icon class="mr-3" dark>mdi-basket-fill</v-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold fonte-subtitulo">
                    {{ produto.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="white--text fonteMini">
                    {{ produto.preco_multiplo.length }} produtos aqui
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    class="animate__animated animate__fadeIn"
                    v-if="get_produto._id === produto._id"
                    icon
                  >
                    <v-icon color="white"> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex v-if="get_produtos.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-6 column centraliza"
            style="height: 36vh;"
          >
            <!-- <img style="width: 80%" src="img/empty.gif" alt="empty" /> -->
            <span
              class="px-3 fonte-italica fonte-subtitulo grey--text text-center"
            >
              Não encontramos nenhuma categoria, clique no botão abaixo e
              cadastre uma!
            </span>
            <v-btn
              small
              rounded
              dark
              class=" mt-6 fonte-subtitulo fonte-italica"
              :color="$theme.secondary"
              @click="iniciarCadastro"
            >
              Nova Categoria
              <v-icon
                size="12"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-arrow-projectile</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9>
        <div
          v-if="get_produto.new || get_produto._id"
          leave-active-class="animate__animated animate__fadeIn"
          class="expande-horizontal wrap animate__animated animate__fadeIn"
        >
          <!-- Header da categoria -->
          <v-flex xs12>
            <div class="windows-style mt-3 px-3 expande-horizontal fonte">
              <v-list color="transparent" width="100%" dense class="pa-0 ma-0">
                <v-list-item class="pa-0 ma-0">
                  <v-avatar
                    @click="backToList"
                    style="cursor: pointer;"
                    size="27"
                    :color="$theme.primary"
                    class="mr-2"
                    icon
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{
                        get_produto.new
                          ? get_produto.nome
                            ? get_produto.nome
                            : "Nova Categoria"
                          : get_produto.nome
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os detalhes abaixo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="get_produto._id">
                    <v-btn
                      @click="excluir_categoria(get_produto)"
                      :color="$theme.primary"
                      x-small
                      class="fonte elevation-0 font-weight-bold text-captalize fonteMini white--text"
                      >Excluir</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </v-flex>

          <div class="windows-style-content expande-horizontal wrap"">
            <!-- Dados da categoria form -->
            <v-flex class="pa-3 pb-0 pr-0" xs12 md10>
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex class="pr-3" xs12 md4>
                      <v-text-field
                        label="Nome"
                        dense
                        outlined
                        class="fonte-subtitulo"
                        placeholder="ex: Pizzas"
                        v-model="get_produto.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md8>
                      <v-text-field
                        label="Descrição"
                        dense
                        outlined
                        class="fonte-subtitulo"
                        placeholder="ex: Pizzas premium"
                        v-model="get_produto.descricao"
                      ></v-text-field>
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- Botão de salvar a categoria -->
            <v-flex xs12 md2 class="pa-3 pb-0">
              <div class="expande-horizontal">
                <v-btn
                  @click="criarOuAtualizar"
                  color="#673AB7"
                  dark
                  block
                  class="elevation-0 fonte font-weight-bold text-capitalize"
                >
                  Salvar
                </v-btn>
              </div>
            </v-flex>
            <!-- Listagem dos produtos -->
            <v-flex class="px-3 pt-0" xs12>
              <div v-if="get_produto._id" class="expande-horizontal wrap">
                <v-flex class="pl-1" xs12>
                  <v-list color="transparent" class="pa-0 ma-0" dense>
                    <v-list-item class="fonte pa-0 ma-0">
                      <v-avatar size="27" color="#673AB7" class="mr-2" icon>
                        <v-icon size="19" color="white">mdi-basket</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          Produtos
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-mini">
                          Gerencie os produtos abaixo
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <div
                        v-if="get_produto.preco_multiplo.length"
                        class="pb-6 expande-horizontal pr-3"
                      >
                        <v-list
                          color="transparent"
                          two-line
                          style="width: 100%;"
                        >
                          <template
                            v-for="(tamanho,
                            index) in get_produto.preco_multiplo"
                          >
                            <v-list-item
                              style="border-radius: 6px; background: #673AB7; margin-bottom: 6px;"
                              :key="index"
                            >
                              <v-switch
                                color="white"
                                v-model="tamanho.disponivel"
                                @change="atualizar_produto"
                              ></v-switch>
                              <v-list-item-content
                                style="cursor: pointer;"
                                @click="
                                  iniciarConfiguracaoFichaTecnica(
                                    tamanho,
                                    index
                                  )
                                "
                              >
                                <v-list-item-title
                                  class="font-weight-bold white--text fonte-subtitulo"
                                >
                                  {{ tamanho.nome }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="white--text fonteMini fonte-subtitulo"
                                >
                                  {{ tamanho.descricao }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="tamanho.type !== 'oferta'"
                                  class="white--text font-weight-bold fonte-subtitulo fonte-italica"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      tamanho.preco_com_markup
                                        ? tamanho.preco_com_markup
                                        : tamanho.preco_com_lp
                                    )
                                  }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="tamanho.type === 'oferta' && tamanho.default_offer?.prices">
                                    <div class="expande-horizontal">
                                    <span v-for="(price, i) in tamanho.default_offer.prices"
                                    :key="`price-offer-${i}`"
                                    class="fonte item-price-offer white--text">{{
                                      $helper.formataSaldo(price.value || 0)
                                    }}</span>
                                    <span v-if="price && price.description" class="fonteMini white--text">{{
                                      price.description
                                    }}</span>
                                    </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn
                                  @click="removePrecoMultiplo(index)"
                                  small
                                  icon
                                >
                                  <v-icon size="16">mdi-delete-outline</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </div>
          <div class="expande-horizontal pa-3 windows-style-bottom">
            <v-flex xs12>
              <v-btn
                dark
                class="fonte font-weight-bold mb-3 elevation-0"
                color="#673AB7"
                @click="iniciaCadastroPrecoMultiplo"
              >
                Adicionar um produto
                <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-flex>
          </div>
        </div>
      </v-flex>
      <v-dialog
        transition="slide-x-transition"
        fullscreen
        persistent
        :value="get_produto._id || get_produto.new ? true : false"
        v-else
      >
        <v-card class="pl-3">
          <div
            v-if="get_produto.new || get_produto._id"
            class="expande-horizontal wrap"
          >
            <v-flex xs12>
              <div class="expande-horizontal px-2 fonte">
                <v-list-item class="pa-0 ma-0">
                  <v-avatar
                    size="27"
                    :color="$theme.primary"
                    @click="backToList"
                    class="mr-2"
                    icon
                  >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{
                        get_produto.new
                          ? get_produto.nome
                            ? get_produto.nome
                            : "Nova Categoria"
                          : get_produto.nome
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os detalhes abaixo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="get_produto._id">
                    <v-avatar
                      size="27"
                      :color="$theme.primary"
                      @click="excluir_categoria(get_produto)"
                    >
                      <v-icon color="white">mdi-delete-circle-outline</v-icon>
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex class="pl-2" xs12>
              <v-form ref="form">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex class="pr-3" xs12 md12>
                      <v-text-field
                        label="Nome"
                        dense
                        outlined
                        class="fonte-subtitulo"
                        placeholder="ex: Pizzas"
                        v-model="get_produto.nome"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pr-3" xs12 md12>
                      <v-text-field
                        label="Descrição"
                        dense
                        outlined
                        class="fonte-subtitulo"
                        placeholder="ex: Pizzas premium"
                        v-model="get_produto.descricao"
                      ></v-text-field>
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <v-flex xs12 class="pb-6 px-2">
              <div class="expande-horizontal">
                <v-flex xs12 md3>
                  <v-btn
                    @click="criarOuAtualizar"
                    color="#673AB7"
                    dark
                    block
                    class="fonte-subtitulo font-weight-bold elevation-0 text-capitalize"
                  >
                    Salvar Categoria
                  </v-btn>
                </v-flex>
              </div>
            </v-flex>
            <v-flex v-if="get_produto._id" xs12>
              <div class="expande-horizontal wrap">
                <v-flex class="pl-2" xs12>
                  <v-list-item class="fonte pa-0 ma-0">
                    <v-avatar color="#673AB7" class="mr-2" icon>
                      <v-icon color="white">mdi-basket</v-icon>
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        Produtos
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-mini">
                        Gerencie os produtos abaixo
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex class="pl-2" xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <div
                        v-if="get_produto.preco_multiplo.length"
                        class="pb-6 expande-horizontal pr-3"
                      >
                        <v-list two-line style="width: 100%;">
                          <template
                            v-for="(tamanho,
                            index) in get_produto.preco_multiplo"
                          >
                            <v-list-item
                              style="border-radius: 6px; background: #673AB7; margin-bottom: 6px;"
                              class="animate__animated animate__fadeInUp"
                              :key="index"
                            >
                              <v-switch
                                color="#fff"
                                v-model="tamanho.disponivel"
                                @change="atualizar_produto"
                              ></v-switch>
                              <v-list-item-content
                                @click="
                                  iniciarConfiguracaoFichaTecnica(
                                    tamanho,
                                    index
                                  )
                                "
                              >
                                <v-list-item-title
                                  class="fonte-subtitulo white--text"
                                >
                                  {{ tamanho.nome }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  class="white--text fonte-subtitulo fonteMini"
                                >
                                  {{ tamanho.descricao }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                  v-if="tamanho.type !== 'oferta'"
                                  class="white--text font-weight-bold fonte-subtitulo fonte-italica"
                                >
                                  {{
                                    $helper.formataSaldo(
                                      tamanho.preco_com_markup
                                        ? tamanho.preco_com_markup
                                        : tamanho.preco_com_lp
                                    )
                                  }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="tamanho.type === 'oferta' && tamanho.default_offer?.prices">
                                    <div class="expande-horizontal">
                                    <span v-for="(price, i) in tamanho.default_offer.prices"
                                    :key="`price-offer-${i}`"
                                    class="fonte item-price-offer white--text">{{
                                      $helper.formataSaldo(price.value || 0)
                                    }}</span>
                                    <span v-if="price && price.description" class="fonteMini white--text">{{
                                      price.description
                                    }}</span>
                                    </div>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn
                                  @click="removePrecoMultiplo(index)"
                                  small
                                  icon
                                >
                                  <v-icon size="16">mdi-delete-outline</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="pr-2">
                      <v-btn
                        dark
                        class="fonte-subtitulo mb-3 elevation-0"
                        color="#673AB7"
                        block
                        @click="iniciaCadastroPrecoMultiplo"
                      >
                        Adicionar um produto
                        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </v-flex>
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <ModalConfigProduct
      ref="modalConfigProduct"
      :product="productToSetup"
      :save="saveProduct"
      :closeProduct="closeProduct"
    />
    <ModalCreateProduct
      ref="modalCreateProduct"
      @createProduct="createProduct"
    />
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import LottieAnimation from "lottie-web-vue";
import ModalConfigProduct from "../components/modalConfigProduct.vue";
import ModalCreateProduct from "../components/modalCreateProduct.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      card: false,
      productToSetup: {},
      showFormPreco: false,
      contextDialog: false,
      drawer: false,
      insumo: {},
      preco_final: 0,
      options: [
        {
          nome: "Nova Categoria",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Categorias",
          path: "/menuonline"
        }
      ],
      get_categoria: {
        produtos: []
      },
      showFormAdicionarInsumo: false,
      nova_secao: "",
      formPrecoMultiplo: {},
      lucro_pretendido: "",
      formInsumoDialog: false,
      showFormFichaTecnica: false,
      precoFichaTecnicaIndex: null,
      precoFichaTecnica: {},
      filter: {
        search: ""
      },
      currentIndex: null
    };
  },
  components: {
    Filtros,
    ModalConfigProduct,
    LottieAnimation,
    ModalCreateProduct
  },
  computed: {
    ...mapGetters([
      "get_insumos_filtros",
      "getLoggedUser",
      "get_produto",
      "get_insumo",
      "getLinks",
      "get_insumos",
      "get_produtos",
      "getLinksById",
      "getPersonalizedLoading",
      "get_invoices",
      "get_invoices_filtros"
    ]),
    categoryFiltereds() {
      let filteredCategories = this.get_produtos.docs;
      if (this.filter.search === "") {
        return filteredCategories;
      } else {
        const searchStr = this.filter.search.toLowerCase();

        filteredCategories = this.get_produtos.docs.filter(category => {
          console.log("ok", searchStr);
          console.log("ok", category.nome.toLowerCase());
          const nameFilter = category.nome.toLowerCase().indexOf(searchStr);
          console.log("ok", nameFilter);
          return !nameFilter;
        });
        return filteredCategories;
      }
    },
    valorTotalComBaseNosInsumos() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(val.preco_de_compra);
        const preco_da_grama = preco_de_compra / 1000;
        const total_de_entrada = quantidade * preco_da_grama;
        return total_de_entrada;
      };
      let total = 0;
      const ficha_tecnica = this.get_produto.ficha_tecnica || [];
      ficha_tecnica.map(estoque => {
        total += calcPreco(estoque);
      });

      return parseFloat(total.toFixed(2));
    }
  },
  filters: {
    filterSaldo(val) {
      if (val) return val;
      return 0;
    },
    calcGramaTotal(val) {
      const quantidade = parseFloat(val.quantidade || 0);
      const preco_de_compra = parseFloat(val.preco_de_compra || 0);
      const preco_da_grama =
        preco_de_compra / val.unidade_estoque_minimo.divisor;
      const total_de_entrada = quantidade * preco_da_grama;
      return total_de_entrada.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  },
  methods: {
    saveProduct() {
      this.get_produto.preco_multiplo[
        this.precoFichaTecnicaIndex
      ] = this.productToSetup;
      this.criarOuAtualizar();
    },
    closeProduct() {
      // let produto = this.get_produto || {};

      // if (
      //   produto &&
      //   produto.preco_multiplo &&
      //   produto.preco_multiplo.length > 0
      // ) {
      //   produto.preco_multiplo = produto.preco_multiplo.filter(
      //     p => p.preco_com_markup && p.nome
      //   );
      // }
      // this.get_produto = produto;
      this.$refs.modalConfigProduct.closeModal();
      this.criarOuAtualizar();
    },
    abrirProduto(produto) {
      this.$store.commit("set_produto", produto);
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_produto.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_produto.preco_multiplo = novoPrecoMultiplo;
      this.atualizar_produto();
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_produtos",
      "listar_insumos",
      "listar_produtocategorias",
      "criar_produto",
      "atualizar_produto",
      "setPersonalizedLoading",
      "createGlobalMessage",
      "createConfirmAction",
      "listar_invoices"
    ]),
    // abrirProdutoRecemCriado() {
    //   const index = this.get_produto.preco_multiplo.length;
    //   this.currentIndex = index;
    //   this.get_produto.preco_multiplo.push({
    //     disponivel: true,
    //     without_precification: true
    //   });
    //   this.formPrecoMultiplo = {
    //     disponivel: true,
    //     without_precification: true
    //   };
    //   this.iniciarConfiguracaoFichaTecnica(
    //     { disponivel: true, without_precification: true },
    //     index
    //   );
    // this.showFormPreco = false;
    // },
    iniciaCadastroPrecoMultiplo() {
      this.$refs.modalCreateProduct.open();
    },

    criarOuAtualizar(silence_update) {
      // let produto = this.get_produto || {};

      // if (
      //   produto &&
      //   produto.preco_multiplo &&
      //   produto.preco_multiplo.length > 0
      // ) {
      //   produto.preco_multiplo = produto.preco_multiplo.filter(
      //     p => p.preco_com_markup && p.nome
      //   );
      // }

      // this.get_produto = produto;

      if (this.get_produto._id) {
        this.atualizar_produto(silence_update);
      } else {
        this.criar_produto();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_produto", {
        new: true,
        preco_multiplo: [],
        ficha_tecnica: []
      });
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    updateField(prod, campo, novo_valor) {
      let produto = prod;
      produto[campo] = novo_valor;
      this.$store.commit("set_produto", produto);
      this.atualizar_produto();
    },
    addProduto() {
      this.criar_produto();
    },
    backToList() {
      this.$store.commit("set_produto", {});
      this.$forceUpdate();
    },
    iniciarConfiguracaoFichaTecnica(preco, index) {
      let adicionais_treateds = [];
      if (Array.isArray(preco.adicionais)) {
        adicionais_treateds = preco.adicionais.filter(a => a);
      }
      this.productToSetup = {
        ...preco,
        adicionais: adicionais_treateds
      };
      this.precoFichaTecnica = preco;
      this.precoFichaTecnicaIndex = index;
      // this.showFormFichaTecnica = true;
      // this.lucro_pretendido = preco.lucro_pretendido || "";
      // this.preco_final = preco.preco_final || 0;
      // this.get_produto.ficha_tecnica = preco.ficha_tecnica || [];
      this.$store.commit("setLinks", preco.imagens || []);
      this.$refs.modalConfigProduct.open();
    },
    createProduct(produto) {
      const index = this.get_produto.preco_multiplo.length;
      this.currentIndex = index;
      this.get_produto.preco_multiplo.push(produto);
      // this.formPrecoMultiplo = {
      //   disponivel: true,
      //   without_precification: true
      // };
      this.iniciarConfiguracaoFichaTecnica(produto, index);
      this.criarOuAtualizar(true);
      // this.showFormPreco = false;
    },
    excluir_categoria(item) {
      let text = item.preco_multiplo.length
        ? `Há ${item.preco_multiplo.length} produtos cadastrados, deseja realmente excluir?`
        : "Confirma exclusão?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_produto",
        action_value: item
      });
    }
  },
  created() {
    this.listar_produtos();
    this.listar_insumos();
    this.listar_invoices();
  }
};
</script>

<style scoped>
.item-selected {
  background: #673ab7 !important;
  color: #fff !important;
}
.item-new {
  background: #e1673c !important;
  color: #fff !important;
}

.list-categories::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.list-categories::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.list-categories::-webkit-scrollbar-thumb {
  background-color: #e1673c; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.windows-style {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f2f2f2;
}
.windows-style-content {
  border-bottom: 1px solid #e0e0e0;
  background: #f2f2f2;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  max-height: 80vh;
  overflow: auto;
}
</style>
