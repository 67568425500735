<template>
  <v-flex xs12>
    <div class="expande-horizontal pa-6 centraliza fonte column">
      <v-flex xs12>
        <div class="expande-horizontal pt-6 wrap">
          <v-flex v-if="itemsSelecteds.length > 0" xs12>
            <div class="expande-horizontal centraliza">
              <v-list-item class="item-list-adicional">
                <v-avatar>
                  <span class="white--text font-weight-bold">{{
                    itemsSelecteds.length
                  }}</span>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="white--text font-weight-bold">
                    {{
                      itemsSelecteds.length > 1
                        ? "itens selecionados"
                        : "item selecionado"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              outlined
              append-icon="mdi-magnify"
              v-model="search_category"
              label="Pesquise uma categoria"
              dense
            ></v-text-field>
          </v-flex>
          <v-flex
            style="max-height: 300px;min-height: 300px; overflow: auto;"
            v-if="searchedCategories.length > 0"
            xs12
          >
            <v-expansion-panels class="expande-horizontal">
              <v-expansion-panel
                v-for="category in searchedCategories"
                :key="category._id"
              >
                <v-expansion-panel-header>
                  {{ category.nome }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-3">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(produto, index) in category.preco_multiplo"
                      :key="`item-adc-internal-${index}`"
                    >
                      <v-expansion-panel-header>
                        {{ produto.nome }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-flex xs12>
                          <v-list color="transparent" nav>
                            <template v-for="(item, index) in produto.adicionais">
                              <v-list-item
                                v-if="item"
                                :key="`item-adc-${index}`"
                                class="item-list-aditional "
                              >
                                <v-avatar>
                                  <v-checkbox
                                    dark
                                    v-model="item.marked_to_import"
                                  ></v-checkbox>
                                </v-avatar>
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="white--text font-weight-bold"
                                  >
                                    {{ item.nome_grupo }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <v-flex class="pa-3" v-else xs12>
            <div class="expande-horizontal centraliza pt-6">
              <v-icon>mdi-empty</v-icon>
              <h3 class="mt-3">
                Não encontramos nada
              </h3>
            </div>
          </v-flex>
          <v-flex class="pt-3" xs12>
            <div class="expande-horizontal centraliza wrap">
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :color="$theme.secondary"
                @click="import_now"
                class="white--text font-weight-bold ma-1"
                >Importar Agora
                <v-icon class="ml-3">mdi-progress-download</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: ["importItems"],
  data() {
    return {
      step: 1,
      search_category: "",
      categories: {
        docs: []
      }
    };
  },
  computed: {
    searchedCategories() {
      let filteredCategories = this.categories.docs;
      if (!this.search_category) {
        return filteredCategories;
      } else {
        const searchStr = this.search_category.toLowerCase();

        filteredCategories = filteredCategories.filter(categorie => {
          const statusMatch =
            categorie.nome.toLowerCase().indexOf(searchStr) >= 0;

          return statusMatch;
        });

        return filteredCategories;
      }
    },
    itemsSelecteds() {
      const categories = this.searchedCategories;
      let items_selecteds = [];
      categories.map(category => {
        const has_preco_multiplo = Array.isArray(category.preco_multiplo);
        if (has_preco_multiplo) {
          category.preco_multiplo.map(product => {
            if (product) {
              if (product.adicionais) {
                product.adicionais.map(adicional => {
                  if (adicional) {
                    if (adicional.marked_to_import) {
                      items_selecteds.push(adicional);
                    }
                  }
                });
              }
            }
          });
        }
      });
      return items_selecteds;
    }
  },
  methods: {
    list_category() {
      this.$run("produtos/list-all", { nome: this.search_category })
        .then(res => {
          this.categories = res.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    import_now() {
      this.importItems(this.itemsSelecteds);
    }
  },
  created() {
    this.list_category();
  }
};
</script>

<style>
.item-list-aditional {
  margin-bottom: 6px;
  background: #673AB7;
  border-radius: 6px;
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
}
</style>
