<template>
  <v-flex xs12>
    <div class="expande-horizontal pa-0 fonte column">
      <v-flex class="pt-6 pb-6" v-if="step === 1" xs12 md3>
        <div
          class="expande-horizontal centraliza"
          style="justify-content: center; !important"
        >
          <v-list color="transparent">
            <v-list-item class="item-list-aditional " @click="step = 3">
              <v-avatar>
                <v-icon color="white">mdi-download-network-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-bold">
                  Importar de outro produto
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="item-list-aditional " @click="step = 2">
              <v-avatar>
                <v-icon color="white">mdi-plus-network-outline</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-bold">
                  Criar um adicional novo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="item-list-aditional "
              @click="$emit('go-to-items')"
            >
              <v-avatar>
                <v-icon color="white">mdi-chevron-left</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-bold">
                  Voltar
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-flex>
      <v-flex v-if="step === 2" class="pa-3" xs12>
        <v-window touchless v-model="windowInsumo">
          <v-window-item :value="1">
            <div class="expande-horizontal white--text column">
              <div class="expande-horizontal centraliza py-6 wrap">
                <v-flex
                  xs12
                  v-if="
                    insumos_ficha_tecnica_de_producao.docs.length > 0 &&
                      insumos_ficha_tecnica_de_producao.docs
                  "
                >
                  <v-list
                    style="max-height: 270px; overflow: auto;"
                    dark
                    nav
                    color="transparent"
                    dense
                  >
                    <template
                      v-for="(itemFicha,
                      i) in insumos_ficha_tecnica_de_producao.docs"
                    >
                      <v-list-item
                        class="item-list-adicional"
                        @click="
                          selecionaInsumoParaAdicionarAoProduto(itemFicha)
                        "
                        :key="i / 2"
                      >
                        <v-icon class="mr-3 ml-1" color="white">mdi-basket</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="fonte font-weight-bold white--text">
                              {{ itemFicha.nome }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="itemFicha.unidade_estoque_minimo">
                            {{ itemFicha.unidade_estoque_minimo.nome }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            x-small
                            text
                            @click="
                              selecionaInsumoParaAdicionarAoProduto(itemFicha)
                            "
                          >
                            <v-icon size="15" class="mr-2"
                              >mdi-plus-circle</v-icon
                            >
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
                <div
                  class="expande-horizontal centraliza column px-6"
                  style="min-height: 270px; border: 1px solid #e0e0e0; border-radius: 6px;"
                  v-else
                >
                  <v-icon>mdi-basket</v-icon>
                  <span class="fonte grey--text text-center"
                    >Faça uma busca abaixo e encontre o insumo</span
                  >
                </div>
              </div>

              <v-flex>
                <v-text-field
                  v-model="filter_insumo_ficha_tecnica"
                  append-icon="mdi-magnify"
                  @click:append="list_insumos_ficha_tecnica_de_producao"
                  @keyup.enter="list_insumos_ficha_tecnica_de_producao"
                  hide-details
                  solo
                  flat
                  dense
                  placeholder="Pesquise o insumo aqui"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <div class="expande-horizontal centraliza column pb-1">
                  <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    :color="$theme.secondary"
                    class="fonte mt-3"
                    :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
                    dark
                    @click="list_insumos_ficha_tecnica_de_producao"
                    >Buscar</v-btn
                  >
                  <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    color="red lighten-2"
                    class="fonte mt-3"
                    :class="{ 'ml-3': $vuetify.breakpoint.lgAndUp }"
                    text
                    dark
                    small
                    @click="$emit('go-to-items')"
                    >Cancelar</v-btn
                  >
                </div>
              </v-flex>
            </div>
          </v-window-item>
          <v-window-item :value="2">
            <div class="expande-horizontal white--text pb-6 column">
              <div class="expande-horizontal column px-3">
                <v-flex xs12>
                  <v-list color="transparent" dense>
                    <v-list-item class="item-list-adicional">
                      <v-icon color="white" class="mr-3 ml-1">mdi-basket</v-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="fonte white--text font-weight-bold">
                            {{ windowInsumoForm.nome }}
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="fonte white--text">
                            Unidade:
                            {{ windowInsumoForm.unidade_estoque_minimo.valor }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex class="fonte --text" xs12>
                  <v-form ref="formAdicional">
                    <v-flex class="pb-2" xs12>
                      <v-text-field
                        filled
                        :rules="[v => !!v || 'Campo obrigatório']"
                        ref="quantidadeDoAdicionalField"
                        v-mask="maskInsumoQtd"
                        :label="
                          `Porção em ${this.calcUnidadeDeMedida(
                            windowInsumoForm
                          ) || ''}`
                        "
                        hint="aviso: 0.250 para 250g"
                        v-model="windowInsumoForm.quantidade"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pb-3" xs12>
                      <v-text-field
                        filled
                        label="Nome personalizado"
                        placeholder="ex: Molho de tomate"
                        :rules="[v => !!v || 'Campo obrigatório']"
                        ref="descricaoPersonalizadaField"
                        v-model="windowInsumoForm.descricao_personalizada"
                      ></v-text-field>
                    </v-flex>
                    <v-flex class="pb-3" xs12>
                      <v-text-field
                        filled
                        :rules="[v => !!v || 'Campo obrigatório']"
                        ref="precoAdicionalField"
                        @keyup.enter.prevent="adicionaItemAdicionalAoForm"
                        v-mask="['#', '#,##', '##,##', '###,##', '####,##']"
                        label="Preço do item"
                        placeholder="ex: 2,50"
                        v-model="windowInsumoForm.preco"
                      ></v-text-field>
                    </v-flex>
                  </v-form>
                </v-flex>
              </div>
              <div class="expande-horizontal centraliza">
                <v-btn
                  x-small
                  text
                  class="fonte red--text mr-2"
                  @click="cancelFormItem"
                >
                  <v-icon size="13" class="mr-1">mdi-chevron-left</v-icon>
                  Cancelar</v-btn
                >
                <v-btn
                  color="green"
                  x-small
                  class="fonte white--text"
                  @click="adicionaItemAdicionalAoForm"
                  >salvar <v-icon>mdi-save</v-icon></v-btn
                >
              </div>
            </div>
          </v-window-item>
        </v-window>
      </v-flex>
      <v-flex xs12 v-if="step === 3">
        <div class="expande-horizontal pt-6 wrap">
          <v-flex v-if="itemsSelecteds.length > 0" xs12>
            <div class="expande-horizontal centraliza">
              <v-list-item class="item-list-adicional">
                <v-avatar>
                  <span class="white--text font-weight-bold">{{
                    itemsSelecteds.length
                  }}</span>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="white--text font-weight-bold">
                    {{
                      itemsSelecteds.length > 1
                        ? "itens selecionados"
                        : "item selecionado"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              outlined
              append-icon="mdi-magnify"
              v-model="search_category"
              label="Pesquise uma categoria"
              dense
            ></v-text-field>
          </v-flex>
          <v-flex
            style="max-height: 300px;min-height: 300px; overflow: auto;"
            v-if="searchedCategories.length > 0"
            xs12
          >
            <v-expansion-panels class="expande-horizontal">
              <v-expansion-panel
                v-for="category in searchedCategories"
                :key="category._id"
              >
                <v-expansion-panel-header>
                  {{ category.nome }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-3">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(produto, index) in category.preco_multiplo"
                      :key="`item-adc-internal-${index}`"
                    >
                      <v-expansion-panel-header>
                        {{ produto.nome }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-flex xs12>
                          <v-list color="transparent" nav>
                            <v-list-item
                              v-for="(item, index) in produto.adicionais"
                              v-show="item"
                              :key="`item-adc-${index}`"
                              class="item-list-aditional "
                            >
                              <v-avatar>
                                <v-checkbox
                                  dark
                                  v-model="item.marked_to_import"
                                ></v-checkbox>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="white--text font-weight-bold"
                                >
                                  {{ item.nome_grupo }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <v-flex class="pa-3" v-else xs12>
            <div class="expande-horizontal centraliza pt-6">
              <v-icon>mdi-empty</v-icon>
              <h3 class="mt-3">
                Não encontramos nada
              </h3>
            </div>
          </v-flex>
          <v-flex class="pt-3" xs12>
            <div class="expande-horizontal centraliza wrap">
              <v-btn
                :color="$theme.secondary"
                v-if="$vuetify.breakpoint.lgAndUp"
                class="white--text font-weight-bold ma-1"
                @click="step = 1"
              >
                <v-icon class="ml-3">mdi-chevron-left</v-icon>
                Voltar
              </v-btn>
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                :color="$theme.secondary"
                @click="import_now"
                class="white--text font-weight-bold ma-1"
                >Importar Agora
                <v-icon class="ml-3">mdi-progress-download</v-icon>
              </v-btn>
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                v-if="$vuetify.breakpoint.smAndDown"
                :color="$theme.secondary"
                class="blue--text font-weight-bold ma-1 mt-3"
                @click="step = 1"
                small
                text
              >
                <v-icon class="ml-3">mdi-chevron-left</v-icon>
                Voltar
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["group", "index_group", "importItems", "saveGroup"],
  data() {
    return {
      step: 2,
      step_add_item: 1,
      search_category: "",
      categories: {
        docs: []
      },
      windowInsumoForm: {
        unidade_estoque_minimo: {}
      },
      windowInsumo: 1,
      filter_insumo_ficha_tecnica: "",
      insumos_ficha_tecnica_de_producao: {
        docs: []
      },
      loading: "",
      rerender: false
    };
  },
  computed: {
    maskInsumoQtd() {
      if (this.windowInsumoForm) {
        if (this.windowInsumoForm.unidade_estoque_minimo.divisor > 1) {
          return ["#.###", "##.###", "###.###"];
        } else {
          return ["#", "##", "###", "####", "#####"];
        }
      } else {
        return ["#", "##", "###", "####", "#####"];
      }
    },
    searchedCategories() {
      let filteredCategories = this.categories.docs;
      if (!this.search_category) {
        return filteredCategories;
      } else {
        const searchStr = this.search_category.toLowerCase();

        filteredCategories = filteredCategories.filter(categorie => {
          const statusMatch =
            categorie.nome.toLowerCase().indexOf(searchStr) >= 0;

          return statusMatch;
        });

        return filteredCategories;
      }
    },
    itemsSelecteds() {
      const categories = this.searchedCategories;
      let items_selecteds = [];
      categories.map(category => {
        const has_preco_multiplo = Array.isArray(category.preco_multiplo);
        if (has_preco_multiplo) {
          category.preco_multiplo.map(product => {
            if (product.adicionais) {
              product.adicionais.map(adicional => {
                if (adicional.marked_to_import) {
                  items_selecteds.push(adicional);
                }
              });
            }
          });
        }
      });
      return items_selecteds;
    }
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    cancelFormItem() {
      this.windowInsumo = 1;
      this.$emit("go-to-items");
    },
    selecionaInsumoParaAdicionarAoProduto(item) {
      const itemTreated = {
        ficha_tecnica: item.ficha_tecnica,
        id_insumo: item.id_insumo,
        nome: item.nome,
        tenant: item.tenant,
        ultimo_preco_de_compra: item.ultimo_preco_de_compra,
        unidade_estoque_minimo: item.unidade_estoque_minimo,
        _id: item._id
      };
      this.windowInsumoForm = itemTreated;
      this.windowInsumo = 2;
      // setTimeout(() => {
      //   this.$refs.quantidadeField.focus();
      // }, 200);
    },
    adicionaItemAdicionalAoForm() {
      if (this.$refs.formAdicional.validate()) {
        var adicionais = [];
        const item = this.windowInsumoForm;
        if (Array.isArray(this.group.itens)) {
          adicionais = [...this.group.itens, item];
        } else {
          adicionais = [item];
        }
        this.group.itens = adicionais;
        this.windowInsumoForm = {
          unidade_estoque_minimo: {}
        };
        this.$forceUpdate();
        this.createGlobalMessage({
          timeout: 3000,
          icon: "mdi-success",
          message: `Item adicionado com sucesso!`,
          type: "success"
        });
        this.windowInsumo = 1;
        this.saveGroup();
        this.windowInsumoForm = {
          unidade_estoque_minimo: {}
        }
        this.$emit("go-to-items");
      }
    },
    list_insumos_ficha_tecnica_de_producao() {
      this.loading = "insumos_ficha_tecnica";
      this.$run("insumos/list", {
        nome: this.filter_insumo_ficha_tecnica
      }).then(res => {
        this.loading = "";
        this.insumos_ficha_tecnica_de_producao = res.data;
      });
    },
    calcUnidadeDeMedida(value) {
      if (
        value.unidade_estoque_minimo.valor == "und" ||
        value.unidade_estoque_minimo.valor == "UND"
      ) {
        return "unidade(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "kg" ||
        value.unidade_estoque_minimo.valor == "KG"
      ) {
        return "quilo(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "g" ||
        value.unidade_estoque_minimo.valor == "GRAMA"
      ) {
        return "grama(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "l" ||
        value.unidade_estoque_minimo.valor == "L"
      ) {
        return "litro(s)";
      } else if (value.unidade_estoque_minimo.valor == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_estoque_minimo.valor;
      }
    },
    list_category() {
      this.$run("produtos/list-all", { nome: this.search_category })
        .then(res => {
          this.categories = res.data;
        })
        .catch(e => {
          console.log(e);
        });
    },
    import_now() {
      this.importItems(this.itemsSelecteds);
      this.$emit("go-to-items");
    }
  },
  created() {
    this.list_category();
  }
};
</script>

<style>
.item-list-aditional {
  margin-bottom: 6px;
  background: #673AB7;
  border-radius: 6px;
  box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 20px 2px rgba(0, 0, 0, 0.1);
}
</style>
