<template>
  <v-dialog
    persistent
    class="cool-roller vitrify"
    fullscreen
    transition="slide-x-transition"
    v-model="opened"
  >
    <div class="expande-horizontal centraliza" :style="` background: white;`">
      <v-flex xs12 md12>
        <div
          class="expande-horizontal column wrap align-top"
          style="align-items: flex-start; justify-content: flex-start;"
        >
          <!-- Header -->
          <div
            style="background: #f2f2f2; border-bottom: 1px solid #e0e0e0;"
            class="centraliza expande-horizontal px-2 pr-3 py-1"
          >
            <v-avatar
              style="cursor: pointer;"
              size="27"
              @click="closeProduct"
              :color="$theme.primary"
              icon
            >
              <v-icon color="#fff">
                mdi-close
              </v-icon>
            </v-avatar>
            <v-list dense color="transparent" class="pa-0 ma-0">
              <v-list-item>
                <v-list-item-content>
                  <span
                    :style="
                      `color: ${$theme.primary};font-size: 12pt; width: 100%;`
                    "
                    class="fonte text-capitalize font-weight-bold"
                  >
                    {{ product.nome }}
                  </span>
                  <v-list-item-subtitle
                    :style="`width: 100%;`"
                    class="fonte grey--text fonteMini text-capitalize "
                  >
                    [{{ product.code }}]
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-spacer></v-spacer>
            <v-btn
              :color="$theme.primary"
              class="white--text fonte font-weight-bold elevation-0"
              small
              @click="save"
            >
              salvar
              <v-icon class="ml-2">mdi-content-save</v-icon>
            </v-btn>
          </div>
          <!-- Fim Header -->
          <div
            class="expande-horizontal cool-roller wrap"
            :style="
              `max-height: ${
                $vuetify.breakpoint.smAndDown ? '90' : '90'
              }vh; overflow: auto;`
            "
          >
            <div class="expande-horizontal column pt-3 fonte">
              <v-flex xs12>
                <div class="expande-horizontal centraliza">
                  <img
                    style="z-index: 300;"
                    @click.prevent="openUploadProfileContainer"
                    class="store-profile"
                    :src="
                      product.image || 'https://via.placeholder.com/400x500'
                    "
                  />
                  <div>
                    <input
                      id="image_profile"
                      type="file"
                      ref="product_image"
                      @change="preUploadProductImage"
                    />
                  </div>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="expande-horizontal centraliza">
                  <div
                    v-if="
                      product_image_progress !== '' &&
                        product_image_progress < 100
                    "
                    style="width: 200px;"
                  >
                    <v-progress-linear
                      :color="$theme.primary"
                      height="3"
                      class="mt-2"
                      style="border-radius: 6px;"
                      :value="product_image_progress"
                      striped
                    ></v-progress-linear>
                  </div>
                </div>
              </v-flex>
            </div>

            <div class="expande-horizontal column pt-3 fonte">
              <v-flex xs12>
                <div class="expande-horizontal centraliza column">
                  <span
                    v-if="product.type !== 'oferta'"
                    class="fonte roller text-center font-weight-bold pa-3"
                  >
                    {{ $helper.formataSaldo(product.preco_com_markup || 0) }}
                  </span>
                  <h2 class="fonte">{{ product.nome }}</h2>
                  <div
                    v-if="product.type == 'oferta' && product.default_offer"
                    class="expande-horizontal centraliza wrap"
                  >
                    <div
                      v-for="(price, i) in product.default_offer.prices"
                      :key="`price-offer-${i}`"
                      class="fonte item-price-offer"
                    >
                      <span class="white--text">{{
                        $helper.formataSaldo(price.value || 0)
                      }}</span>
                      <span class="fonteMini white--text">{{
                        price.description
                      }}</span>
                    </div>
                  </div>
                  <div
                    v-if="product.type == 'oferta' && product.default_offer"
                    class="expande-horizontal centraliza column wrap"
                  >
                    <span class="fonte" v-if="product.default_offer">
                      <v-icon size="19" :color="$theme.primary"
                        >mdi-flag</v-icon
                      >
                      {{
                        product.default_offer.description ||
                          "Sem oferta definida"
                      }}
                      é a oferta ativa
                    </span>
                    <span
                      class="grey--text fonteMini"
                      v-if="product.default_offer"
                    >
                      Esta oferta tem
                      {{ product.default_offer.prices.length }} opções de preço
                    </span>
                  </div>
                </div>
              </v-flex>
              <v-flex xs12>
                <div class="expande-horizontal centraliza wrap">
                  <!-- <v-flex xs12>
                    <div class="expande-horizontal centraliza">
                      <v-switch
                        outlined
                        dense
                        :color="$theme.secondary"
                        v-model="product.without_precification"
                        @change="setPrecification($event)"
                        label="Desejo informar preço manual"
                      ></v-switch>
                    </div>
                  </v-flex> -->
                  <span
                    v-if="product.type && product.type == 'precificado'"
                    class="grey--text text-center"
                    >Preço com base nas contas, ajuste o lucro!</span
                  >
                  <!-- <span v-else class="grey--text text-center"
                    >Informe o preço!</span
                  > -->
                  <v-flex xs12></v-flex>
                  <v-chip
                    small
                    color="red lighten-1"
                    v-if="
                      product.type &&
                        product.type == 'precificado' &&
                        parseInt(product.lucro_pretendido || 0) > 0 &&
                        !product.ficha_tecnica
                    "
                    class="white--text text-center"
                  >
                    <v-icon class="mr-3">mdi-flag-outline</v-icon> Insira itens
                    na ficha técnica para precificar!</v-chip
                  >
                </div>
              </v-flex>
            </div>

            <div
              v-if="product.type && product.type == 'precificado'"
              class="pr-3 pl-3 expande-horizontal fonte centraliza pt-3 pb-6"
            >
              <v-flex xs12 md7>
                <v-slider
                  v-model="product.lucro_pretendido"
                  color="#673AB7"
                  :label="`${product.lucro_pretendido}%`"
                  thumb-color="#673AB7"
                  track-color="#e5e5e5"
                  max="60"
                  min="1"
                  @change="calcPrecos('nosave')"
                  thumb-label="P%"
                  thumbLabel=""
                ></v-slider>
              </v-flex>
            </div>

            <div
              v-if="$vuetify.breakpoint.smAndDown"
              class="expande-horizontal px-3 mb-2 centraliza"
            >
              <v-flex
                v-if="product.type == 'oferta'"
                class="pr-2 fonte"
                xs4
                md2
              >
                <v-card
                  @click="tab = 'ofertas'"
                  hover
                  class="expande-horizontal elevation-0 column centraliza"
                  height="30"
                  style="border-radius: 12px;"
                  :color="tab === 'ofertas' ? '#673AB7' : '#f2f2f2'"
                  :class="tab === 'ofertas' ? 'font-weight-bold' : 'grey--text'"
                  :dark="tab === 'ofertas'"
                  outlined
                >
                  <v-icon
                    size="18"
                    :color="tab === 'ofertas' ? 'white' : 'grey'"
                    >mdi-info</v-icon
                  >
                  Ofertas</v-card
                >
              </v-flex>
              <v-flex class="pr-2 fonte" xs4 md2>
                <v-card
                  @click="tab = 'resumo'"
                  hover
                  height="30"
                  style="border-radius: 12px;"
                  class="expande-horizontal elevation-0 column centraliza"
                  :color="tab === 'resumo' ? '#673AB7' : '#f2f2f2'"
                  :class="tab === 'resumo' ? 'font-weight-bold' : 'grey--text'"
                  :dark="tab === 'resumo'"
                  outlined
                >
                  <v-icon size="18" :color="tab === 'resumo' ? 'white' : 'grey'"
                    >mdi-info</v-icon
                  >
                  Detalhes</v-card
                >
              </v-flex>
              <v-flex
                v-if="
                  product.type &&
                    ['precificado', 'ficha_tecnica'].includes(product.type)
                "
                class="pr-2 fonte"
                xs4
                md2
              >
                <v-card
                  @click="tab = 'ficha'"
                  hover
                  class="expande-horizontal elevation-0 column centraliza"
                  height="30"
                  style="border-radius: 12px;"
                  :color="tab === 'ficha' ? '#673AB7' : '#f2f2f2'"
                  :class="tab === 'ficha' ? 'font-weight-bold' : 'grey--text'"
                  :dark="tab === 'ficha'"
                  outlined
                >
                  Ficha</v-card
                >
              </v-flex>
              <v-flex class="pr-2 fonte" xs4 md2>
                <v-card
                  @click="tab = 'adicional'"
                  hover
                  class="expande-horizontal elevation-0 column centraliza"
                  height="30"
                  style="border-radius: 12px;"
                  :color="tab === 'adicional' ? '#673AB7' : '#f2f2f2'"
                  :class="
                    tab === 'adicional' ? 'font-weight-bold' : 'grey--text'
                  "
                  :dark="tab === 'adicional'"
                  outlined
                >
                  Adicionais</v-card
                >
              </v-flex>
            </div>

            <div class="expande-horizontal wrap">
              <div
                class="expande-horizontal centraliza wrap"
                style="align-items: flex-start;"
              >
                <v-flex
                  v-if="
                    ($vuetify.breakpoint.mdAndUp || tab === 'ofertas') &&
                      product.type == 'oferta'
                  "
                  class="pa-3"
                  xs12
                  md4
                >
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <div class="expande-horizontal black--text column">
                        <div class="pa-3 expande-horizontal centraliza">
                          <h3 class="fonte black--text ml-1">
                            Ofertas
                          </h3>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="elevation-0 fonte white--text font-weight-bold"
                            rounded
                            dark
                            @click="openNewOfferDialog"
                          >
                            Nova oferta
                            <v-icon right>
                              mdi-plus-circle-outline
                            </v-icon>
                          </v-btn>
                        </div>
                        <v-list
                          v-if="product.offers"
                          dense
                          style="border-radius: 12px;"
                        >
                          <v-list-item
                            :style="
                              `background: ${
                                offer.default ? '#8BC34A' : '#f2f2f2'
                              }; border-radius: 12px; border: 1px solid #e5e5e5; margin-bottom: 6px;`
                            "
                            :key="offer.description"
                            @click="setOfferDefault(indexOffer)"
                            v-for="(offer, indexOffer) in product.offers"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <span
                                  class="fonte font-weight-bold"
                                  :class="{
                                    'white--text': offer.default,
                                    'black--text': !offer.default
                                  }"
                                >
                                  {{ offer.description }}
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-chip
                                  small
                                  :key="`list-suboffer-${i}`"
                                  v-for="(subOffer, i) in offer.prices"
                                  class="fonte mr-2 black--text"
                                >
                                  {{ subOffer.description }} -
                                  {{ $helper.formataSaldo(subOffer.value) }}
                                </v-chip>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <!-- <v-btn
                                  small
                                  color="#8BC34A"
                                  dark
                                  text
                                  block
                                  class="mt-2"
                                  v-if="!offer.default"
                                  @click="setOfferDefault(indexOffer)"
                                  >Definir como padrão</v-btn
                                > -->
                                <!-- <v-btn
                                  small
                                  color="white"
                                  block
                                  class="mt-2"
                                  v-else
                                  >Esta é a oferta ativa</v-btn
                                > -->
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <!-- <v-list-item-action>
                              <v-btn
                                v-if="!offer.default"
                                icon
                                @click="removeOffer(offer)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-action> -->
                            <v-list-item-action>
                              <v-btn
                                v-if="offer.default"
                                icon
                                style="background: #f2f2f2;"
                                @click="removeOffer(offer)"
                              >
                                <v-icon>mdi-check-all</v-icon>
                              </v-btn>
                              <!-- <v-btn
                                v-if="!offer.default"
                                class="elevation-6"
                                style="background: #8BC34A;"
                                @click="setOfferDefault(indexOffer)"
                              >
                                <v-icon color="#f2f2f2">mdi-check</v-icon>
                              </v-btn> -->
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
                <v-flex
                  v-if="$vuetify.breakpoint.mdAndUp || tab === 'resumo'"
                  class="pa-3 pt-0 mb-3"
                  xs12
                  md4
                >
                  <div class="expande-horizontal wrap">
                    <v-flex class="mb-4" xs12>
                      <div
                        v-if="
                          product.type &&
                            ['ficha_tecnica', 'precificado'].includes(
                              product.type
                            )
                        "
                        class="expande-horizontal white--text column gradient"
                      >
                        <div class="pa-3 expande-horizontal centraliza">
                          <v-icon>mdi-cash-fast</v-icon>
                          <h3 class="fonte white--text ml-1">
                            Resumo de Preços
                          </h3>
                          <v-spacer></v-spacer>
                        </div>
                        <h3 class="fonte white--text pa-3 pt-0 pb-0">
                          {{ $helper.formataSaldo(product.preco_com_lp || 0) }}
                        </h3>
                        <span class="fonte white--text pa-3 pt-0 pb-0">
                          Preço com base no lucro pretendido de
                          {{ product.lucro_pretendido }}%.
                          <!-- <v-btn
                            dark
                            block
                            small
                            outlined
                            rounded
                            class="mt-1"
                            @click="$router.push('/invoices')"
                            >veja suas contas aqui</v-btn
                          > -->
                        </span>
                        <v-divider class="my-3"></v-divider>
                        <h4 class="fonte white--text pa-3 pt-0 pb-0">
                          {{ $helper.formataSaldo(product.preco_insumos || 0) }}
                        </h4>
                        <span class="fonte white--text pa-3 pt-0 pb-3">
                          Preço com base na ficha técnica
                        </span>
                        <!-- <v-card
                          color="transparent"
                          light
                          class="expande-horizontal"
                        >
                          <v-flex xs12>
                            <v-form ref="formLucro">
                              <v-flex xs12 class="pa-3 pb-0">
                                <v-text-field
                                  solo
                                  dense
                                  v-mask="['#.##', '##.##']"
                                  @input="calcPrecos"
                                  v-model="product.lucro_pretendido"
                                  prefix="Lucro pretendido:"
                                  suffix="%"
                                ></v-text-field>
                              </v-flex>
                            </v-form>
                          </v-flex>
                        </v-card> -->
                      </div>
                    </v-flex>
                    <v-flex class="pt-0" xs12>
                      <div class="expande-horizontal">
                        <v-flex xs12>
                          <v-form ref="formProduct">
                            <v-flex xs12>
                              <v-text-field
                                filled
                                hide-details
                                :color="$theme.secondary"
                                prefix="R$"
                                style="height: 70px;"
                                ref="precoManual"
                                v-if="
                                  !product.type ||
                                    (product.type &&
                                      product.type == 'preco_unico')
                                "
                                v-mask="['#.##', '##.##', '###.##', '####.##']"
                                v-model="product.preco_com_markup"
                                label="Preço"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 class="">
                              <v-text-field
                                outlined
                                ref="nameProduct"
                                dense
                                @keyup.enter.prevent="$refs.inputValue.focus()"
                                v-model="product.nome"
                                label="Nome do produto"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 class="">
                              <v-text-field
                                outlined
                                ref="codeProduct"
                                dense
                                @keyup.enter.prevent="$refs.nameProduct.focus()"
                                v-model="product.code"
                                label="Código do produto"
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 class="">
                              <v-text-field
                                outlined
                                ref="productDescription"
                                dense
                                @keyup.enter.prevent="$refs.inputValue.focus()"
                                v-model="product.descricao"
                                label="Descrição"
                              ></v-text-field>
                            </v-flex>
                          </v-form>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
                <v-flex
                  v-if="
                    tab === 'ficha' ||
                      (product.type &&
                        ['ficha_tecnica', 'precificado'].includes(
                          product.type
                        ) && $vuetify.breakpoint.mdAndUp)
                  "
                  class="pa-3 pt-0 mb-3"
                  xs12
                  md4
                >
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-window v-model="windowInsumo">
                        <v-window-item :value="1">
                          <div
                            class="expande-horizontal pa-6 white--text column gradient-insumos"
                          >
                            <div class="centraliza pb-3 expande-horizontal">
                              <v-icon>mdi-list-box-outline</v-icon>
                              <h3 class="fonte white--text ml-1">
                                Ficha Técnica
                              </h3>
                              <v-spacer></v-spacer>
                            </div>
                            <v-spacer></v-spacer>
                            <!-- <div class="expande-horizontal">
                              <h1 class="fonte white--text">
                                {{
                                  $helper.formataSaldo(product.preco_insumos || 0)
                                }}
                              </h1>
                            </div> -->
                            <div class="expande-horizontal column">
                              <span class="fonte white--text text-center">
                                Este produto é composto por
                                {{
                                  product.ficha_tecnica
                                    ? product.ficha_tecnica.length
                                    : 0
                                }}
                                insumo(s), confira abaixo.
                              </span>
                              <v-btn
                                dark
                                class="elevation-0 font-weight-bold"
                                rounded
                                @click="windowInsumo = 2"
                              >
                                Adicionar insumo
                                <v-icon right>
                                  mdi-chevron-right
                                </v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            class="expande-horizontal white--text column gradient-insumos-form"
                          >
                            <div
                              class="expande-horizontal centraliza px-3 py-6 wrap"
                            >
                              <div class="expande-horizontal">
                                <h3 class="fonte white--text">
                                  Pesquise e selecione o insumo:
                                </h3>
                              </div>
                              <v-flex
                                xs12
                                v-if="
                                  get_insumos.docs.length > 0 &&
                                    get_insumos.docs
                                "
                              >
                                <v-list color="transparent" dense>
                                  <template
                                    v-for="(itemFicha, i) in get_insumos.docs"
                                  >
                                    <v-list-item
                                      class="item-list-insumo"
                                      @click="
                                        selecionaInsumoParaAdicionarAoProduto(
                                          itemFicha
                                        )
                                      "
                                      :key="i / 2"
                                    >
                                      <v-list-item-title>
                                        <span class="fonte white--text">
                                          {{ itemFicha.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-action>
                                        <v-btn
                                          x-small
                                          text
                                          @click="
                                            selecionaInsumoParaAdicionarAoProduto(
                                              itemFicha
                                            )
                                          "
                                        >
                                          Adicionar
                                          <v-icon size="15" class="mr-2"
                                            >mdi-plus-circle</v-icon
                                          >
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-flex>
                              <div v-else>
                                <span
                                  class="fonte white--text white--text text-center"
                                  >Sem resultados, faça uma busca abaixo</span
                                >
                              </div>
                            </div>
                            <v-card
                              light
                              flat
                              :color="$theme.secondary"
                              class="expande-horizontal px-3 centraliza"
                            >
                              <InsumosFiltro style="color: #333" />
                              <v-btn
                                color="red lighten-2"
                                class="fonte white--text"
                                small
                                @click="windowInsumo = 1"
                                >Cancelar</v-btn
                              >
                            </v-card>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3">
                          <div
                            class="expande-horizontal pt-3 pb-3 white--text column gradient-insumos-form"
                          >
                            <div class="expande-horizontal column px-3">
                              <div class="expande-horizontal">
                                <h3 class="fonte white--text">
                                  Informe a porção a adicionar:
                                </h3>
                              </div>
                              <v-flex xs12>
                                <v-list color="transparent" dense>
                                  <v-list-item class="item-list-insumo">
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <span class="fonte white--text">
                                          {{ windowInsumoForm.nome }}
                                        </span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span class="fonte white--text">
                                          Unidade:
                                          {{
                                            windowInsumoForm
                                              .unidade_estoque_minimo.valor
                                          }}
                                        </span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-flex>
                              <v-flex xs12>
                                <v-form ref="formInsumo" submit="false">
                                  <v-flex xs12>
                                    <v-text-field
                                      solo
                                      dense
                                      :rules="[v => !!v || 'Campo obrigatório']"
                                      ref="quantidadeField"
                                      v-mask="maskInsumoQtd"
                                      @keyup.enter.prevent="
                                        finalizar_e_salvar_insumo
                                      "
                                      :prefix="
                                        `Quantidade em ${
                                          windowInsumoForm &&
                                          windowInsumoForm.unidade_estoque_minimo
                                            ? windowInsumoForm
                                                .unidade_estoque_minimo.valor
                                            : ''
                                        }: `
                                      "
                                      v-model="windowInsumoForm.quantidade"
                                    ></v-text-field>
                                  </v-flex>
                                </v-form>
                              </v-flex>
                            </div>
                            <div class="expande-horizontal centraliza">
                              <v-btn
                                x-small
                                text
                                class="fonte white--text mr-2"
                                @click="windowInsumo = 1"
                              >
                                <v-icon size="13" class="mr-1"
                                  >mdi-chevron-left</v-icon
                                >
                                Cancelar</v-btn
                              >
                              <v-btn
                                color="green"
                                small
                                class="fonte white--text"
                                @click="finalizar_e_salvar_insumo"
                                >Finalizar e adicionar
                                <v-icon>mdi-save</v-icon></v-btn
                              >
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                    <v-flex xs12>
                      <v-flex xs12>
                        <h5 class="fonte font-color mt-3 mb-2 pl-1">
                          Confira a ficha técnica abaixo:
                        </h5>
                      </v-flex>
                      <v-list
                        color="transparent"
                        v-if="
                          product.ficha_tecnica && product.ficha_tecnica.length
                        "
                        dense
                      >
                        <template
                          v-for="(itemFicha, i) in product.ficha_tecnica"
                        >
                          <v-list-item
                            class="item-list-ficha"
                            @click="() => {}"
                            :key="i / 2"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                <span
                                  class="fonte white--text font-weight-bold"
                                >
                                  {{ itemFicha.quantidade }}
                                  {{ itemFicha.unidade_estoque_minimo.valor }}
                                  de
                                  {{ itemFicha.nome }}
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle class="fonte white--text">
                                + {{ itemFicha | calcGramaTotal }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                icon
                                @click="removerInsumo(false, itemFicha, i)"
                              >
                                <v-icon color="white">mdi-minus</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                      <div v-else class="expande-horizontal centraliza">
                        <span
                          class="fonte white--text grey--text pa-6 text-center"
                        >
                          Este produto ainda não possui insumos, adicione um
                          acima.
                        </span>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
                <!-- parteee para adicionar adicionais -->
                <v-flex
                  v-if="$vuetify.breakpoint.mdAndUp || tab === 'adicional'"
                  class="pa-3 pt-0 mb-3"
                  xs12
                  md4
                >
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-window v-model="windowAdicional">
                        <v-window-item :value="1">
                          <div
                            class="expande-horizontal px-6 pt-3 pb-3 white--text column gradient-adicionais-form"
                          >
                            <div class="expande-horizontal pb-3">
                              <v-icon>mdi-format-list-group</v-icon>
                              <h3 class="fonte white--text">
                                Grupos de Adicionais
                              </h3>
                              <v-spacer></v-spacer>
                            </div>
                            <span class="fonte text-center white--text">
                              Este produto tem
                              {{
                                product.adicionais
                                  ? product.adicionais.length
                                  : 0
                              }}
                              grupo(s) de adicional(is) cadastrado(s).
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn
                              class="elevation-0 fonte white--text font-weight-bold"
                              rounded
                              dark
                              @click="openAditionals"
                            >
                              Novo adicional
                              <v-icon right>
                                mdi-plus-circle-outline
                              </v-icon>
                            </v-btn>
                            <!-- <v-btn
                              class="elevation-0 mt-2 fonte white--text font-weight-bold"
                              rounded
                              dark
                              @click="openImportAditionals"
                            >
                              Importar adicional
                              <v-icon right>
                                mdi-download
                              </v-icon>
                            </v-btn> -->
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            class="expande-horizontal white--text column pt-3 pb-3 gradient-adicionais-form"
                          >
                            <div class="expande-horizontal px-3 wrap">
                              <v-flex xs12>
                                <v-flex xs12>
                                  <h4 class="fonte white--text">
                                    Itens neste grupo:
                                  </h4>
                                </v-flex>
                                <v-flex xs12>
                                  <v-list
                                    class="fonte white--text ma-0"
                                    style="border-radius: 6px;"
                                    v-if="windowAdicionalForm.itens.length"
                                  >
                                    <template
                                      v-for="(item,
                                      i) in windowAdicionalForm.itens"
                                    >
                                      <v-list-item :key="i / 5">
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ item.quantidade }}
                                            {{ calcUnidadeDeMedida(item) }} de
                                            {{ item.nome }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                            {{
                                              $helper.formataSaldo(item.preco)
                                            }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-btn
                                            icon
                                            @click="
                                              removerAdicionalDoGrupo(
                                                true,
                                                item,
                                                i
                                              )
                                            "
                                          >
                                            <v-icon>mdi-minus</v-icon>
                                          </v-btn>
                                        </v-list-item-action>
                                      </v-list-item>
                                    </template>
                                  </v-list>
                                  <div
                                    v-else
                                    class="expande-horizontal centraliza pa-3"
                                    style="min-height: 100px;"
                                  >
                                    <span
                                      >Você ainda não adicionou nada, adicione
                                      abaixo.</span
                                    >
                                  </div>
                                </v-flex>
                              </v-flex>
                              <v-flex class="mt-3" xs12>
                                <v-card
                                  v-if="!windowInsumoForm.nome"
                                  class="pb-3"
                                  color="#333"
                                >
                                  <div
                                    class="expande-horizontal wrap centraliza px-3 pl-0"
                                  >
                                    <v-flex class="pa-3" xs12>
                                      <h4 class="fonte white--text white--text">
                                        Adicione itens ao grupo:
                                      </h4>
                                    </v-flex>
                                    <v-divider></v-divider>
                                    <v-flex
                                      xs12
                                      v-if="
                                        get_insumos.docs.length > 0 &&
                                          get_insumos.docs
                                      "
                                    >
                                      <v-list color="transparent" dense>
                                        <template
                                          v-for="(itemFicha,
                                          i) in get_insumos.docs"
                                        >
                                          <v-list-item
                                            @click="
                                              selecionaAdicionalParaAdicionarAoProduto(
                                                itemFicha
                                              )
                                            "
                                            :key="i / 3"
                                          >
                                            <v-list-item-title>
                                              <span class="fonte white--text">
                                                {{ itemFicha.nome }}
                                              </span>
                                            </v-list-item-title>
                                            <v-list-item-action>
                                              <v-btn
                                                x-small
                                                text
                                                @click="
                                                  selecionaAdicionalParaAdicionarAoProduto(
                                                    itemFicha
                                                  )
                                                "
                                              >
                                                Adicionar
                                                <v-icon size="15" class="mr-2"
                                                  >mdi-plus-circle</v-icon
                                                >
                                              </v-btn>
                                            </v-list-item-action>
                                          </v-list-item>
                                        </template>
                                      </v-list>
                                    </v-flex>
                                    <div v-else>
                                      <span
                                        class="fonte white--text white--text text-center"
                                        >Sem resultados, faça uma busca
                                        abaixo</span
                                      >
                                    </div>
                                  </div>
                                  <v-card light flat color="transparent">
                                    <v-flex xs12>
                                      <InsumosFiltro class="pl-3" />
                                    </v-flex>
                                  </v-card>
                                  <v-btn
                                    class="ml-3 fonte red--text"
                                    x-small
                                    light
                                    @click="windowAdicional = 1"
                                    >Cancelar</v-btn
                                  >
                                  <v-btn
                                    class="ml-3 fonte dark--text"
                                    x-small
                                    light
                                    @click="
                                      finalizar_e_salvar_grupo_de_adicional
                                    "
                                    >Finalizar e salvar grupo</v-btn
                                  >
                                </v-card>
                              </v-flex>
                            </div>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                    <v-flex xs12>
                      <v-flex xs12>
                        <h5 class="fonte font-color mt-3 mb-2 pl-1">
                          Confira os grupos cadastrados abaixo:
                        </h5>
                      </v-flex>
                      <v-list
                        color="transparent"
                        v-if="product.adicionais && product.adicionais.length"
                        dense
                      >
                        <template v-for="(grupo, i) in product.adicionais">
                          <v-list-item
                            v-if="grupo"
                            class="item-list-adicional"
                            :key="`item-adicional-${i}`"
                          >
                            <v-list-item-content
                              style="cursor: pointer;"
                              @click="openAditionals(i, grupo)"
                            >
                              <v-list-item-title>
                                <span
                                  class="fonte white--text fonte white--text-bold"
                                  >{{ grupo.nome_grupo }}
                                </span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <span class="fonte white--text"
                                  >{{ grupo.itens.length }} itens
                                </span>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <span class="fonte white--text"
                                  >{{
                                    grupo.obrigatorio
                                      ? "Obrigatório"
                                      : "Opcional"
                                  }}
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn @click="removerGrupo(false, grupo, i)">
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                      <div
                        v-else
                        style="border: 1px solid #e0e0e0; border-radius: 6px; margin-top: 12px;"
                        class="expande-horizontal centraliza"
                      >
                        <span
                          class="fonte white--text grey--text pa-6 text-center"
                        >
                          Este produto ainda não possui adicionais, adicione um
                          acima.
                        </span>
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </div>
            </div>
            <!-- <v-flex xs12>
              <div class="expande-horizontal px-3 column">
                <h3 class="fonte">Imagens adicionais</h3>
                <ModalSendArchive />
              </div>
            </v-flex> -->
          </div>
        </div>
      </v-flex>
      <modalAditionals
        :save="finalizar_e_salvar_grupo_de_adicional"
        ref="modalAditional"
      />
      <importAditional
        :saveImportAditionals="saveImportAditionals"
        ref="modalImportAditional"
      />
      <v-dialog
        fullscreen
        transition="slide-x-transition"
        v-model="cropProfileImage"
      >
        <v-card
          dark
          class="expande-horizontal wrap centraliza"
          style="min-height: 100vh;"
        >
          <v-flex xs12>
            <v-list class="pa-0 ma-0 fonte">
              <v-list-item class="pr-6" @click="cropProfileImage = false">
                <v-avatar class="mr-3" :color="$theme.secondary" icon>
                  <v-icon color="#fff">mdi-arrow-left</v-icon>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    Ajuste a foto do produto
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >será considerado apenas o que estiver
                    selecionado</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    @click="uploadProductImage"
                    :color="$theme.secondary"
                    dark
                    >Salvar</v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-flex>
          <div class="pl-6 pr-6 expande-horizontal wrap">
            <v-flex xs12>
              <div style="width: 100%; height: 80vh;">
                <vue-cropper
                  style="border-radius: 6px;"
                  ref="cropper20"
                  :img="cropProfileImageData"
                  :output-size="option2.size"
                  :output-type="option2.outputType"
                  :info="true"
                  :full="option2.full"
                  :fixed="fixed"
                  :fixed-number="fixedNumber"
                  :can-move="option2.canMove"
                  :can-move-box="option2.canMoveBox"
                  :fixed-box="option2.fixedBox"
                  :original="option2.original"
                  :auto-crop="option2.autoCrop"
                  :auto-crop-width="option2.autoCropWidth"
                  :auto-crop-height="option2.autoCropHeight"
                  :center-box="option2.centerBox"
                  @real-time="realTime"
                  :high="option2.high"
                  @img-load="imgLoad"
                  mode="cover"
                  :max-img-size="option2.max"
                  @crop-moving="cropMoving"
                  @crop-complete="uploadProductImage"
                ></vue-cropper>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="newOfferDialog" width="350">
      <v-card width="350">
        <div class="expande-horizontal column">
          <v-list-item>
            <v-avatar style="cursor: pointer;" @click="closeNewOfferDialog">
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonte">Nova oferta</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="expande-horizontal pa-3">
            <v-flex xs12>
              <v-form class="fonte" ref="formNewOffer" submit="false">
                <!-- <v-flex xs12>
                  <v-checkbox
                    filled
                    dense
                    class="fonte"
                    label="Definir como oferta padrão?"
                    v-model="newOffer.value"
                  ></v-checkbox>
                </v-flex> -->
                <v-flex xs12>
                  <v-text-field
                    filled
                    dense
                    :rules="[v => !!v || 'Campo obrigatório']"
                    ref="inputValue"
                    placeholder="Nome da oferta"
                    v-model="newOffer.description"
                  ></v-text-field>
                </v-flex>
                <v-flex class="pb-3" xs12>
                  <div class="expande-horizontal wrap">
                    <v-chip
                      :color="$theme.primary"
                      v-for="(price, index) in newOffer.prices"
                      :key="`suboffer-${index}`"
                      dark
                      class="mr-2 mt-1"
                    >
                      {{ price.description }} por {{ price.value }}
                      <v-btn class="ml-2" x-small @click="deleteOffer" icon>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-chip>
                  </div>
                </v-flex>
                <div class="expande-horizontal">
                  <v-flex xs3>
                    <v-text-field
                      filled
                      dense
                      ref="inputValue"
                      placeholder="Desc"
                      v-model="newSubOffer.description"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="px-2" xs7>
                    <v-text-field
                      filled
                      dense
                      ref="inputValue"
                      v-mask="['#.##', '##.##', '###.##', '####.##']"
                      :placeholder="`Preço`"
                      v-model="newSubOffer.value"
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="
                      newOffer.prices.length === 0 ||
                        newSubOffer.description.length > 0 ||
                        newSubOffer.value.length
                    "
                    xs2
                  >
                    <v-btn @click="addNewSubOffer" icon class="mt-1">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-flex>
                </div>
                <!-- <v-flex xs12>
                  <v-checkbox
                    filled
                    dense
                    class="fonte"
                    label="Permitir mesclar com outras ofertas?"
                    v-model="newOffer.allows_merging"
                  ></v-checkbox>
                </v-flex> -->
                <v-flex v-if="newOffer.allows_merging" xs12>
                  <v-select
                    filled
                    dense
                    :items="mergingOptions"
                    :rules="[v => !!v || 'Campo obrigatório']"
                    ref="inputValue"
                    placeholder="Forma de cobrança"
                    v-model="newOffer.merging_from"
                  ></v-select>
                </v-flex>
                <v-flex class="mt-2" xs12>
                  <v-btn @click="saveOffer" color="green" dark block>
                    Salvar Oferta
                  </v-btn>
                </v-flex>
              </v-form>
            </v-flex>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import InsumosFiltro from "../../Insumos/components/Filtros.vue";
import Roller from "vue-roller";
import { VueCropper } from "vue-cropper";
import modalAditionals from "./modalAditionals/index.vue";
import importAditional from "./importAdicional/index.vue";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";

export default {
  components: {
    ModalSendArchive,
    InsumosFiltro,
    Roller,
    modalAditionals,
    importAditional,
    VueCropper
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    },
    closeProduct: {
      type: Function,
      default: () => {}
    }
  },
  filters: {
    calcGramaTotal(val) {
      const quantidadeStringed = `${val.quantidade}`;
      const quantidade = parseInt(quantidadeStringed.replace(".", ""));
      const preco_de_compra = parseFloat(val.ultimo_preco_de_compra);
      const preco_do_divisor =
        preco_de_compra / val.unidade_estoque_minimo.divisor;
      const total_de_entrada = quantidade * preco_do_divisor;
      return total_de_entrada.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    },
    filterUnidade(value) {
      if (
        value.unidade_estoque_minimo.valor == "un" ||
        value.unidade_estoque_minimo.valor == "und" ||
        value.unidade_estoque_minimo.valor == "UND" ||
        value.unidade_estoque_minimo.valor == "UN"
      ) {
        return "unidade(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "kg" ||
        value.unidade_estoque_minimo.valor == "KG"
      ) {
        return "quilo(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "g" ||
        value.unidade_estoque_minimo.valor == "GRAMA"
      ) {
        return "grama(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "l" ||
        value.unidade_estoque_minimo.valor == "L"
      ) {
        return "litro(s)";
      } else if (value.unidade_estoque_minimo.valor == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_estoque_minimo.valor;
      }
    }
  },
  data() {
    return {
      product_image_progress: "",
      option2: {
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 120,
        autoCropHeight: 120,
        centerBox: false,
        high: false,
        max: 9999
      },
      fixed: true,
      fixedNumber: [4, 5],
      cropProfileImageData: "",
      cropProfileImage: false,
      opened: false,
      windowInsumo: 1,
      windowAdicional: 1,
      windowInsumoForm: {
        unidade_estoque_minimo: {}
      },
      windowAdicionalForm: {
        itens: []
      },
      tab: "",
      mergingOptions: [
        { text: "Preço do maior produto", value: "most_price" },
        { text: "Metade do preço de cada um", value: "half_half" }
      ],
      newOffer: {
        default: false,
        total_price: 0,
        prices: [],
        from: 0,
        to: 0,
        allows_merging: false,
        merging_from: "half_half"
      },
      newSubOffer: {
        value: "",
        description: ""
      },
      newOfferDialog: false
    };
  },
  watch: {
    windowInsumo(val) {
      if (val == 2) {
        this.get_insumos_filtros.limitPerPage = 2;
        this.listar_insumos();
      }
    },
    windowAdicional(val) {
      if (val == 2) {
        this.get_insumos_filtros.limitPerPage = 2;
        this.listar_insumos();
        this.windowInsumoForm = {
          unidade_estoque_minimo: {}
        };
      }
    },
    "product.lucro_pretendido": function(val) {
      if (val && !this.product.without_precification) {
        this.calcPrecos();
      }
    }
  },
  computed: {
    ...mapGetters(["get_insumos", "get_insumos_filtros", "get_invoices"]),
    maskInsumoQtd() {
      if (this.windowInsumoForm) {
        if (this.windowInsumoForm.unidade_estoque_minimo.divisor > 1) {
          return ["#.###", "##.###", "###.###"];
        } else {
          return ["#", "##", "###", "####", "#####"];
        }
      } else {
        return ["#", "##", "###", "####", "#####"];
      }
    }
  },
  methods: {
    openNewOfferDialog() {
      this.newOfferDialog = true;
      this.newOffer = {
        default: false,
        total_price: 0,
        prices: [],
        from: 0,
        to: 0,
        allows_merging: false,
        merging_from: ""
      };
    },
    closeNewOfferDialog() {
      this.newOfferDialog = false;
      this.newOffer = {
        default: false,
        total_price: 0,
        prices: [],
        from: 0,
        to: 0,
        allows_merging: false,
        merging_from: ""
      };
    },
    saveOffer() {
      if (!this.product.offers) {
        this.product.offers = [];
      }
      this.product.offers.push(this.newOffer);
      if (this.newOffer.default) {
        this.product.preco_com_markup = this.newOffer.prices[0].value;
        this.setOfferDefault(this.product.offers.length - 1);
      }
      this.closeNewOfferDialog();
      this.save();
    },
    removeOffer(index) {
      this.newOffer.prices.splice(index, 1);
      this.save();
    },
    setOfferDefault(index) {
      this.product.offers = this.product.offers.map((offer, i) => {
        if (i == index) {
          offer.default = true;
          this.product.default_offer = offer;
        } else {
          offer.default = false;
        }
        return offer;
      });
      this.save();
    },
    addNewSubOffer() {
      this.newOffer.prices.push(this.newSubOffer);
      this.newSubOffer = {
        value: "",
        description: ""
      };
    },
    removeSubOffer(index) {
      this.newOffer.prices.splice(index, 1);
    },
    cropMoving() {},
    cropImg() {},
    imgLoad() {},
    realTime() {},
    openUploadProfileContainer() {
      this.$refs.product_image.click();
    },
    preUploadProductImage() {
      const input = this.$refs.product_image;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.cropProfileImageData = e.target.result;
          this.cropProfileImage = true;
        };
      };
      reader.readAsDataURL(file);
    },
    async uploadProductImage() {
      this.$refs.cropper20.getCropBlob(async blob => {
        // Convertendo o Blob para um File para acessar o tipo MIME
        const file = new File([blob], "tempfile", { type: blob.type });

        // Mapeamento de tipos MIME para extensões de arquivo
        const mimeToExtension = {
          "image/jpeg": "jpg",
          "image/png": "png",
          "image/gif": "gif",
          "image/webp": "webp"
          // Adicione mais tipos MIME se necessário
        };

        const extension = mimeToExtension[file.type] || "png"; // Default para png se o tipo MIME não estiver mapeado
        const fileName = `product_images/${this.product.uuid ||
          this.generateUUID()}.${extension}`;

        const storage = getStorage();
        const storageRef = ref(storage);
        const imageRef = ref(storageRef, fileName);

        const taskLoad = uploadBytesResumable(imageRef, file, {
          contentType: file.type
        });

        taskLoad.on(
          "state_changed",
          // Progresso
          snapshot => {
            this.product_image_progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.error("Upload error:", error);
          },
          // Sucesso
          () => {
            getDownloadURL(taskLoad.snapshot.ref).then(link => {
              this.product.image = link;
              this.save();
              setTimeout(() => {
                this.$forceUpdate();
              }, 300);
            });
          }
        );
      });
    },

    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    openImportAditionals() {
      this.$refs.modalImportAditional.open();
    },
    saveImportAditionals(list) {
      if (Array.isArray(this.product.adicionais)) {
        const new_adicionais = [...this.product.adicionais, ...list];
        this.product.adicionais = new_adicionais;
      } else {
        this.product.adicionais = list;
      }
      this.save();
    },
    openAditionals(index, insumo) {
      this.$refs.modalAditional.open(index, insumo);
    },
    saveGroup() {},
    ...mapActions([
      "createConfirmAction",
      "listar_insumos",
      "createGlobalMessage"
    ]),
    setPrecification(val) {
      if (val) {
        this.product.preco_com_markup = "";
        setTimeout(() => {
          this.$refs.precoManual.focus();
        }, 300);
      } else {
        this.calcPrecos();
      }
    },
    calcUnidadeDeMedida(value) {
      if (
        value.unidade_estoque_minimo.valor == "und" ||
        value.unidade_estoque_minimo.valor == "UND"
      ) {
        return "unidade(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "kg" ||
        value.unidade_estoque_minimo.valor == "KG"
      ) {
        return "quilo(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "g" ||
        value.unidade_estoque_minimo.valor == "GRAMA"
      ) {
        return "grama(s)";
      } else if (
        value.unidade_estoque_minimo.valor == "l" ||
        value.unidade_estoque_minimo.valor == "L"
      ) {
        return "litro(s)";
      } else if (value.unidade_estoque_minimo.valor == "ml") {
        return "mililitro(s)";
      } else {
        return value.unidade_estoque_minimo.valor;
      }
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_menu._id ? this.atualizar_menu() : this.criar_menu();
      }
    },
    open() {
      this.opened = false;
      this.opened = true;
    },
    closeModal() {
      this.opened = false;
    },

    removerInsumo(confirmation, item, index) {
      if (confirmation) {
        this.product.ficha_tecnica.splice(index, 1);
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Removemos ${item.quantidade} ${this.calcUnidadeDeMedida(
            item
          )} de ${item.nome} do produto ${this.product.nome} com sucesso!`,
          type: "success"
        });
        this.calcPrecos();
      } else {
        this.createConfirmAction({
          message: `Deseja remover ${
            item.quantidade
          } ${this.calcUnidadeDeMedida(item)} de ${item.nome}?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerInsumo(true, item, index),
          action_value: "ok"
        });
      }
    },
    removerGrupo(confirmation, item, index) {
      if (confirmation) {
        this.product.adicionais.splice(index, 1);
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Grupo removido com sucesso`,
          type: "success"
        });
        this.$forceUpdate();
      } else {
        this.createConfirmAction({
          message: `Deseja remover este grupo?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerGrupo(true, item, index),
          action_value: "ok"
        });
      }
      this.save();
    },
    removerAdicionalDoGrupo(confirmation, item, index) {
      if (confirmation) {
        this.windowAdicionalForm.itens.splice(index, 1);
        this.createGlobalMessage({
          timeout: 1500,
          icon: "mdi-success",
          message: `Removemos ${item.quantidade} ${this.calcUnidadeDeMedida(
            item
          )} de ${item.nome} do grupo ${
            this.windowAdicionalForm.nome_grupo
          } com sucesso!`,
          type: "success"
        });
      } else {
        this.createConfirmAction({
          message: `Deseja remover ${
            item.quantidade
          } ${this.calcUnidadeDeMedida(item)}?`,
          icone: "mdi-warning",
          isScoped: true,
          action: () => this.removerAdicionalDoGrupo(true, item, index),
          action_value: "ok"
        });
      }
    },
    selecionaInsumoParaAdicionarAoProduto(item) {
      this.windowInsumoForm = item;
      this.windowInsumo = 3;
      setTimeout(() => {
        this.$refs.quantidadeField.focus();
      }, 400);
    },
    selecionaAdicionalParaAdicionarAoProduto(item) {
      this.windowInsumoForm = item;
    },
    adicionaItemAdicionalAoForm() {
      if (this.$refs.formAdicional.validate()) {
        var adicionais = [];
        const item = this.windowInsumoForm;
        if (Array.isArray(this.windowAdicionalForm.itens)) {
          adicionais = [...this.windowAdicionalForm.itens, item];
        } else {
          adicionais = [item];
        }
        this.windowAdicionalForm.itens = adicionais;
        this.windowInsumoForm = {
          unidade_estoque_minimo: {}
        };
        this.$forceUpdate();
        this.createGlobalMessage({
          timeout: 3000,
          icon: "mdi-success",
          message: `Item adicionado com sucesso!`,
          type: "success"
        });
      }
    },
    finalizar_e_salvar_insumo() {
      let quantidade;
      if (this.windowInsumoForm.unidade_estoque_minimo.divisor > 1) {
        quantidade = parseFloat(this.windowInsumoForm.quantidade).toFixed(3);
      } else if (this.windowInsumoForm.unidade_estoque_minimo.divisor === 1) {
        quantidade = parseInt(this.windowInsumoForm.quantidade);
      }
      if (this.$refs.formInsumo.validate()) {
        if (!this.product.ficha_tecnica) {
          this.product.ficha_tecnica = [];
        }
        this.product.ficha_tecnica.push({
          ...this.windowInsumoForm,
          quantidade
        });
        this.windowInsumo = 1;
        this.calcPrecos();
        this.createGlobalMessage({
          timeout: 6000,
          icon: "mdi-success",
          message: `Adicionamos ${
            this.windowInsumoForm.quantidade
          } ${this.calcUnidadeDeMedida(this.windowInsumoForm)} de ${
            this.windowInsumoForm.nome
          } ao produto ${this.product.nome} com sucesso!`,
          type: "success"
        });
      }
    },
    calcPrecos(option) {
      const calcPreco = val => {
        const quantidadeStringed = `${val.quantidade}`;
        const quantidadeConverted = parseInt(
          quantidadeStringed.replace(".", "")
        );
        const preco_de_compra = val.ultimo_preco_de_compra;
        const preco_do_divisor =
          preco_de_compra / val.unidade_estoque_minimo.divisor;
        const total_de_entrada = quantidadeConverted * preco_do_divisor;
        return parseFloat(total_de_entrada);
      };
      let total = 0;
      const ficha_tecnica = this.product.ficha_tecnica || [];
      ficha_tecnica.map(estoque => {
        total += calcPreco(estoque);
      });

      const preco_com_lp =
        parseFloat(total) +
        (parseFloat(total) / 100) * parseInt(this.product.lucro_pretendido);
      // const preco_com_markup =
      this.product.preco_insumos = parseFloat(total).toFixed(2);
      this.product.preco_com_lp = parseFloat(preco_com_lp).toFixed(2);
      this.product.preco_com_markup = this.calcMarkup(
        parseFloat(total).toFixed(2)
      );
      if (option === "nosave") {
        this.save();
      }
    },
    calcMarkup(val) {
      const DV = 15;
      const DF = 17;

      const LP = parseFloat(this.product.lucro_pretendido || 0);

      const preco_com_markup = 100 / (100 - (DV + DF + LP));

      this.product.markup = preco_com_markup;
      return preco_com_markup * val;
    },
    editar_grupo_adicional(index) {
      this.windowAdicionalForm = this.product.adicionais[parseInt(index)];
      this.windowAdicional = 2;
    },
    finalizar_e_salvar_grupo_de_adicional(group, index) {
      if (Array.isArray(this.product.adicionais)) {
        if (index === 0 || index) {
          // verifica dessa forma pq o index nesse momento é um texto
          let new_adicionais = this.product.adicionais;
          new_adicionais[index] = group;
          this.product.adicionais = [];
          this.product.adicionais = new_adicionais;
        } else {
          this.product.adicionais.push(group);
          const index2 = this.product.adicionais.length - 1;
          this.$refs.modalAditional.setIndexGroup(index2);
        }
      } else {
        this.product.adicionais = [group];
        this.$refs.modalAditional.setIndexGroup(0);
      }
      this.createGlobalMessage({
        timeout: 1500,
        icon: "mdi-success",
        message: `O grupo foi salvo!`,
        type: "success"
      });
      this.save();
    },
    calc_lucro_pretendido(val) {
      const lucro = parseFloat(val) || 0;
      this.product.preco_final = this.valorTotalComBaseNosInsumos;
      this.product.preco_final += (this.preco_final / 100) * lucro;
      this.product.preco_final = parseFloat(this.preco_final.toFixed(2));
    }
  }
};
</script>

<style lang="scss">
// @import "vue-roller/dist/style.css";

.item-list-ficha {
  border-radius: 5px;
  margin-bottom: 6px;
  color: #3cacba;
  background: #673ab7;
}
.item-list-adicional {
  background: #673ab7;
  border-radius: 5px;
  margin-bottom: 6px;
}
.item-list-insumo {
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 6px;
}

.align-top {
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 97vh;
}

.cool-roller::-webkit-scrollbar-thumb {
  background-color: #e1673c; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.cool-roller::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.cool-roller::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.gradient {
  background: #673ab7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #673ab7,
    #673ab7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673ab7, #673ab7);
  border-radius: 5px;
  min-height: 200px;
  color: white;
}
.gradient-insumos {
  background: #673ab7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #673ab7,
    #673ab7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673ab7, #673ab7);
  border-radius: 5px;
  height: 200px;
  justify-content: center;
  align-items: center;
}
.gradient-insumos-form {
  background: #673ab7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #673ab7,
    #673ab7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673ab7, #673ab7);
  border-radius: 5px;
  min-height: 200px;
  justify-content: center;
  align-items: center;
}
.gradient-adicionais-form {
  background: #673ab7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #673ab7,
    #673ab7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #673ab7, #673ab7);
  border-radius: 5px;
  min-height: 200px;
}
.cart-resume-price {
  height: 20vh;
}
.roller {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: #673ab7;
}
.store-profile {
  width: 120px;
  height: 120px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.store-profile:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item-price-offer {
  display: flex;
  flex-direction: column;
  margin-right: 6px;
  margin-bottom: 6px;
  background: #8bc34a;
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 6px;
}
</style>
